.product-form {
	color: grey;
	.container {
		padding: unset;
	}

	label {
		margin-top: 10px;
		margin-bottom: 5px;
	}

	.custom-ingredient {
		margin: 0;
		color: #3f65f1;
		a {
			cursor: pointer;
			&:hover {
				border-bottom: 3px dashed #3f65f1;
			}
		}
	}

	.serving_size {
		flex-flow: row-reverse nowrap;
		height: 46px;
		.input-group-append {
			top: -8px;
			position: relative;
		}

		img {
			margin-right: 5px;
			border-radius: 10px;
			height: 70px;
			width: 70px;
		}

		.test__control {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			border: 2px solid #cedbe9;
			border-left: none;
			width: 80px;
			height: 46px;
		}
	}

	.brand__control {
		border: 2px solid #cedbe9;
		border-radius: 6px;
		box-shadow: none;
		height: 45px;
		&:focus {
			border: 2px solid rgba(0, 0, 0, 0.25);
		}
	}
	.ingredients {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;

		.ingredient-item-container {
			display: flex;
			height: 40px;
			justify-content: space-between;
			align-items: center;
			padding-right: 10px;

			img {
				height: 24px;
				width: 24px;
			}

			label {
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		img {
			margin: 0 5px;
			border-radius: 10px;
			height: 70px;
			width: 70px;
		}
		.ingredient__detail {
			flex-flow: row-reverse nowrap;
			.input-group-append {
				top: -8px;
				position: relative;
			}

			img {
				margin-right: 5px;
				border-radius: 10px;
				height: 70px;
				width: 70px;
			}

			.test__control {
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
				border: 2px solid #cedbe9;
				border-left: none;
				width: 80px;
				height: 39px;
			}
		}

		.product-ingredient {
			margin: 10px;
			flex-flow: column nowrap;
			display: flex;
			label {
				width: 80%;
			}
			.ingredient__detail {
				display: flex;
				img {
					margin-right: 5px;
					border-radius: 10px;
					height: 70px;
					width: 70px;
				}
			}

			.form-control {
				width: 100%;
				height: 30px;
				border-radius: 5px;
			}

			.form-group {
				width: 80%;
			}

			.remove-ingredient {
				position: relative;
				left: 95%;
				cursor: pointer;
				top: 18px;
			}
		}

		.form-group {
			width: 50%;
		}
	}

	span.save {
		display: flex;
		justify-content: flex-end;
		button {
			// background-color: #3f65f1;
			color: white;
		}
	}

	.ingredient__price {
		height: 44px;
		flex-flow: row-reverse nowrap;
		.input-group-append {
			top: -8px;
			position: relative;
		}

		img {
			margin-right: 5px;
			border-radius: 10px;
			height: 70px;
			width: 70px;
		}

		.test__control {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			border: 2px solid #cedbe9;
			border-left: none;
			width: 90px;
			height: 44px;
		}

		.test__value-container {
			margin-right: 5px;
		}
	}

	.ingredient-file-fields {
		.file-field {
			display: flex;
			flex-direction: column;
			.field-divider {
				justify-self: flex-end;
				margin-top: auto;
				hr {
					width: 100%;
					height: 1px;
					border-bottom: none;
					border-top: 2px dashed #e1ecf3;
					margin-top: 30px;
				}
			}
		}
	}

	.resource-upload-section {
		color: #2c3e50;
		h5 {
			margin-bottom: unset;
			margin-right: 10px;
		}
		.confirm-selection {
			width: 100%;
			margin-top: 10px;
			button {
				padding: 3px 6px;
				margin-right: 10px;
				color: "#fff";
				span {
					margin-right: 5px;
					color: #fff;
				}
				&.cancel {
					background-color: #e85a73;
				}
				&.confirm {
					background-color: #0ed7ac;
				}
			}
		}
		.document {
			align-items: center;
			flex-wrap: wrap;
			.file-name {
				margin-top: 5px;
				overflow-x: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			section {
				padding: 3px 6px;
				border-radius: 6px;
				margin-left: auto;
				position: relative;
				top: 0;
				transition: top 0.2s ease-in-out;
				&.resource-upload {
					z-index: 10;
					border: 2px solid #8fa1b3;
					&.disabled {
						background-color: hsl(0, 0%, 95%);
					}
					svg {
						color: #8fa1b3;
					}
					&:hover:not(.disabled) {
						cursor: pointer;
						svg {
							color: #fff;
						}
						background-color: #3f65f1;
						border-color: #3f65f1;
						top: -2px;
					}
				}
				&.remove {
					border: 2px solid #e85a73;
					background-color: #e85a73;
					&.disabled {
						opacity: 0.5;
					}
					svg {
						color: #fff;
					}
					&:hover {
						top: -2px;
					}
				}
			}
		}
		.country-file-upload {
			flex-wrap: nowrap;
			margin-top: 10px;
			> .col {
				flex: 1 1 auto; /* Positive flex-shrink */
				max-width: 75%;
			}
		}
		.resource-country-select {
			position: relative;
			margin-top: 4px;
			margin-right: 10px;
			.country-selection {
				position: relative;
				cursor: default;
				height: 31.5px;
				display: flex;
				align-items: center;
				padding: 6px 12px;
				border: 2px solid rgb(206, 219, 233);
				border-radius: 6px;
				width: 100%;
				white-space: nowrap;
				text-overflow: ellipsis;
				svg {
					margin-left: 10px;
				}
				&:hover {
					border-color: #3f65f1;
				}
			}
			.countries-menu {
				z-index: 100;
				position: absolute;
				max-height: 300px;
				background-color: #fff;
				border: 1px solid #e1ecf3;
				border-radius: 6px;
				overflow: hidden;
				&:hover {
					overflow-y: scroll;
				}
				ul {
					list-style: none;
					background-color: #fff;
					padding: unset;
					width: max-content;
					li {
						padding: 6px 12px;
						border-bottom: 1px dashed rgb(206, 219, 233);
						white-space: nowrap;
						&:hover {
							background-color: #e1ecf3;
							cursor: default;
						}
					}
				}
			}
		}
	}
}
