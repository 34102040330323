.teaser-screenshot-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    div {
        width: 700px;
        height: 375px;
        margin:  25px 25px;
        box-shadow: 0 5px 16px 0 rgba(57, 57, 57, 0.15);
        border-radius: 12px;
    }
}
.upgrade-modal-container{
    @media (min-width: 576px) {
        &.modal-dialog {
            max-width: 750px;
            margin: 1.75rem auto;
        }
    }
    box-shadow: 0 5px 16px 0 rgba(57, 57, 57, 0.15);
    width: 750px;
    .modal-content {
        border: none; 
        padding: 16px; 
        border-radius: 16px;
        width: 750px;
        .upgrade-modal-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 0;
            justify-content: center;
            #close-prompt {
                position: absolute;
                float: right; 
                top: 0;
                right: 0;
                cursor: pointer;
                margin: 10px 10px;
            }
            p {
                &.details {
                    margin-top: 30px; 
                    text-align: center;
                    font-size: 16px;
                }
            }
            h3 {
                text-align: center;
                width: 50%;
                a {
                    color: #0ed7ac;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
            .upgrade-modal-button {
                margin-top: 15px;
                padding: 12px;
                background-color: #3f65f1;
                border: none;
                border-radius: 6px; 
                color: #fff;
                span {
                    margin-left: 12px;
                }
                &:active {
                    border: none;
                }
            }
            .subscription {
                .subscription-list {
                    display: flex;
                    flex-flow: row;
                    margin-top: 30px;
                }
            
                .subscription-item {
                    margin-right: 20px;
                    height: 300px;
                    min-width: 215px;
                    border-radius: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
            
                    .name {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-top-left-radius: 12px;
                        border-top-right-radius: 12px;
                        p {
                            color: #fff;
                            font-weight: 600;
                            margin-bottom: 0;
                            font-size: 16px;
                        }
                        height: 15%;
                    }
                    .details {
                        background-color: #fff;
                        height: 85%;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        padding: 15px;
                        .plans {
                            display: flex;
                            flex-flow: row nowrap;
                        }
            
                        .plan {
                            padding: 10px;
                            .price {
                                margin-left: 5px;
                                color: "#273F52";
                                font-size: 36px;
                                font-weight: 600;
                            }
                            ul {
                                padding-inline-start: 15px;
                            }
                            li {
                                color: #657280;
                                font-size: 14px;
                            }
                            .active-subscription-btn {
                                background-color: #3f65f1;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}