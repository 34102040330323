.form-group {
	&.verification-code {
		justify-content: center;
		div.input-group-container {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			width: 400px;
			input {
				width: 50px;
				height: 75px;
				font-size: 2rem;
				text-align: center;
				border-radius: 6px;
			}
		}
	}
}
