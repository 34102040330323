.concept-products {
    .title {
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
        h5 {
            margin-right: 5px;
        }
        h5.count {
            color: #779afc;
        }
    }
    .concept-products-list {
        height: 50vh;
        display: block;
        padding: 0;
        .product-item {
            width: 370px;
            display: inline-block;
            position: relative;
            margin: 10px 15px;
        }
    }
}
