.reports {
    padding: 10px 0;

    .upload-form {
        @extend .border-radius-12;
        box-shadow: 0 5px 16px 0 rgba(57, 57, 57, 0.15);
        div.title {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background-color: $very-light-blue;
            width: 100%;
            display: flex;
            height: 50px;
            align-items: center;
            font-size: 1.3em;

            i {
                color: #3f65f1;
            }
        }

        form {
            margin: 5px 20px;
            min-height: 100px;
            justify-content: space-between;
        }
    }

    .page-header {
        display: flex;
        align-items: center;

        h4 {
            flex: 1;
        }
    }

    table {
        margin-top: 30px;
        font-size: 16px;
        tr {
            border-bottom: 1px solid #80808033;
            td {
                vertical-align: middle;
                &.actions {
                    padding: 10px 0;
                    span {
                        @extend .btn, .btn-outline-warning, .mr-2;
                        display: flex;
                        align-items: center;
                        &.submitted {
                            border: 1px solid #f5a84f;
                            color: #f5a84f;      
                        }

                        &.submitted:hover {
                            color: white;
                        }
                    
                        &.uploaded {
                            border: 1px solid #51c6ac;
                            color: #51c6ac;
                            background-color: white;
                        }

                    }
                }
            }
        }
    }

    span {
        padding: 5px;
        border-radius: 7px;
    
        &.submitted {
          border: 1px solid #f5a84f;
          color: #f5a84f;      
        }
    
        &.uploaded {
          border: 1px solid #51c6ac;
          color: #51c6ac;
          background-color: white;
        }
    }
}
