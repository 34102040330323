.modal-dialog {
	&.notification-modal {
		.modal-title {
			svg:first-of-type,
			img:first-of-type {
				margin-right: 10px;
			}
		}
		&.recommendation-v2 {
			font-family: "Euclid Circular A";
			max-width: unset;
			width: 900px;
			font-size: 14px;

			.modal-content {
				height: 740px;
			}

			.modal-header {
				cursor: default;
				border: none;
			}
			.modal-title {
				svg:first-of-type,
				img:first-of-type {
					margin-right: 0px;
				}
			}

			.col,
			.col-md-auto {
				padding: 0;
			}

			.row {
				margin: 0;
			}

			.modal-header {
				padding: 1rem 0;

				.card-header-label {
					font-style: italic;
					font-size: 16px;
					font-weight: bold;
					border-radius: 0 3px 3px 0;
					color: #f9f9fa;
					.ingredient-recommendation {
						background-color: #ffbc10;
						padding: 7px 10px;
						border-radius: 0 5px 5px 0;
						color: #f9f9fa;
					}
					.packaging-recommendation {
						background-color: #395efa;
						padding: 7px 10px;
						border-radius: 0 5px 5px 0;
					}
					.supplier-recommendation {
						background-color: #53ce7c;
						padding: 7px 10px;
						border-radius: 0 5px 5px 0;
					}
				}

				.card-body-label {
					box-sizing: border-box;
					border-radius: 2px;
					font-size: 14px;
					text-align: center;
					width: 80px;
					padding: 1px;
					font-style: italic;

					&.suggested {
						margin-right: 25px;
						color: #1dd6ae;
					}

					&.requested {
						margin-right: 25px;
						color: #074ee8;
					}
				}
			}

			.modal-body {
				padding: 0px 20px 10px 20px;
				cursor: default;

				.card-name {
					color: rgba(4, 25, 40, 0.5);
				}
				.product-card {
					padding: 12px 25px 22px 25px;
					margin-bottom: 20px;
				}

				h5 {
					font-weight: 600;
					font-size: 16px;
					line-height: 16px;
					color: #141c3f;
				}

				.recommendation-text {
					font-weight: 400;
					font-family: "Inter";
					color: #041928;
					&b {
						font-weight: 700;
					}
				}

				.circle {
					width: 10px;
					height: 10px;
					border-radius: 10px;
					&.gray {
						background-color: #0419284d;
					}

					&.purple {
						background-color: #c747f499;
					}
				}

				.ingredient-container {
					max-width: 220px;

					.image-wrapper {
						width: 80px;
						height: 80px;
						border-radius: 80px;
						overflow: hidden;
						img {
							width: 100%;
							height: 100%;
						}
					}

					.ingredient-text {
						color: rgba(4, 25, 40, 0.8);
						display: flex;
						flex-direction: column;
						align-items: center;
						line-height: 18px;
						white-space: nowrap;
						&b {
							font-weight: bold;
							font-size: 12px;
							line-height: 16px;
							text-align: center;
						}
						&i {
							font-style: italic;
						}
					}
				}

				.arrow-container {
					max-width: 100px;
				}

				.recommendation-grid {
					padding-top: 0.8rem;
					font-size: 12px;
					font-weight: 600;
					font-family: "Inter";
					display: flex;
					align-items: center;
					line-height: 25px;
					.col,
					.col-4 {
						padding: 0;
					}
					.grid-name {
						color: #000000;
						font-family: "Biotif";
						font-size: 14px;
						min-width: 140px;
						max-width: 140px;
					}

					.old-value {
						min-width: 220px;
						max-width: 220px;
						font-size: 14px;
					}

					.percent {
						min-width: 100px;
						max-width: 100px;
						background: #0419281a;
						border-radius: 2px;
						font-size: 14px;
					}
					.success-percent {
						min-width: 100px;
						max-width: 100px;
						background: #0ed7ac1a;
						border-radius: 2px;
						color: #1dd6ae;
					}
					.negative-percent {
						min-width: 100px;
						max-width: 100px;
						background: #ff59591a;
						border-radius: 2px;
						color: #ef5050;
					}

					.new-value {
						min-width: 220px;
						max-width: 220px;
						color: #c747f4;
						font-size: 14px;
					}
					.grid-action {
						min-width: 130px;
						max-width: 130px;
					}
				}

				.portfolio-text {
					line-height: 15px;
					padding-top: 2rem;
					span {
						color: gray;
						font-size: 12px;
					}
					h4 {
						font-size: 14px;
						font-weight: 400;
						margin-bottom: 0;
					}
				}
				.view-button {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 13px;
					color: #17c9dc;
					border: 1px solid #17c9dc;
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 26px;
					padding-right: 3px;
					border-radius: 3px;
					&:hover {
						background-color: #17c9dc;
						color: white;
					}

					svg {
						margin-left: 5px;
					}
				}

				.dataset-container {
					// border: 1px solid;
					// padding: 2px 0;
					display: flex;
					width: 150px;
					justify-content: space-between;
					border: 1px solid rgba(4, 25, 40, 0.1);
					border-radius: 4px;
					span {
						// padding: 7px 10px;
						// border-right: 1px solid;
						border-right: 1px solid rgba(4, 25, 40, 0.1);
						flex: 1;
						font-family: "Inter";
						font-weight: 500;
						font-size: 12px;
						align-items: center;
						text-align: center;
						cursor: pointer;
					}
					.active-span {
						background-color: #ebebec;
						color: #53ce7c;
					}
					span:last-child {
						border-right: none;
					}
				}

				.compare-container {
					padding: 8px 25px;
					font-family: "Biotif";

					h5 {
						font-style: normal;
						font-weight: bold;
						font-size: 14px;
						line-height: 19px;
						color: #041928;
						margin: 0;
					}
				}

				.recommendation-container {
					position: fixed;
					bottom: 0px;
					left: 0;
					padding: 20px 20px;
					width: 100%;

					span {
						font-family: Inter;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						color: rgba(4, 25, 40, 0.8);
					}
					h4 {
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 22px;
						color: #17c9dc;
						margin: 0;
						cursor: pointer;
					}
				}

				.recommendation-analysis-header {
					font-weight: 600;
				}

				.recommendation-analysis-content {
					font-weight: bold;
					font-size: 14px;
					text-align: center;
					color: #04192880;
				}
				.pink {
					color: #c747f4;
				}

				.suppliers-content {
					align-items: center;
					.suppliers-content-header {
						font-family: "Biotif";
						font-weight: bold;
						font-size: 16px;
						line-height: 22px;
						text-align: center;
						color: #000000;
					}
					.large {
						min-width: 270px;
						text-align: start;
						padding-left: 45px;
					}

					.name {
						font-family: "Inter";
						font-size: 14px;
						color: #041928;
					}
					.suppliers-content-box {
						font-family: "Inter";
						text-align: center;
						font-weight: bold;
						font-size: 14px;
						color: #1dd6ae;
						background: rgba(14, 215, 172, 0.1);
						padding: 5px 15px;
					}
				}

				.portfolio-content {
					align-items: center;
					text-align: center;
					.portfolio-content-header {
						font-family: "Biotif";
						font-weight: bold;
						font-size: 16px;
						line-height: 22px;
						text-align: center;
						color: #000000;
					}
					.large {
						min-width: 270px;
						text-align: start;
						padding-left: 45px;
					}

					.name {
						font-family: "Inter";
						font-size: 14px;
						color: #041928;
					}
					.portfolio-content-box {
						font-family: "Inter";
						text-align: center;
						font-weight: bold;
						font-size: 14px;
						color: #1dd6ae;
						background: rgba(14, 215, 172, 0.1);
						padding: 5px 15px;
					}
				}
				.recommendation-btn-success {
					background: #17c9dc;
					box-shadow: 0px 0px 20px rgba(4, 25, 40, 0.05);
					border-radius: 8px;
					padding: 10px 30px 6px;
					border-color: #17c9dc;
					text-align: center;
					align-items: center;
				}
			}

			.cost-prediction-card {
				padding: 10px 15px;
			}
			.card {
				margin-bottom: 20px;
			}

			.start-container {
				display: flex;
				justify-content: space-between;
				width: 300px;
				margin-top: 1rem;
				svg {
					stroke: #17C9DC;
				}
			}
		}
		&.recommendation {
			max-width: unset;
			width: 700px;
			.modal-body .col {
				padding: 0;
				.row {
					flex-wrap: nowrap;
					margin: 0;
					img {
						height: 125px;
						width: 125px;
						border-radius: 6px;
						border: 1px dashed #e1ecf3;
					}
					div.summary {
						display: flex;
						flex-direction: column;
						a {
							margin-top: auto;
							margin-bottom: 0;
						}
					}
				}
			}
			.rec-product {
				p {
					margin-bottom: 0;
				}
			}
			.action-buttons {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
			}
		}
		h6 {
			margin-bottom: 12px;
			padding: 6px;
			background-color: #e1ecf3;
			border-radius: 6px;
			width: -moz-fit-content;
			width: fit-content;
			// workaround for IE11
			display: table;
		}
		.rec-tags {
			margin-bottom: 15px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			span {
				padding: 6px;
				border: 1px solid #e1ecf3;
				border-radius: 6px;
				margin-right: 10px;
				margin-top: 5px;
			}
		}
		.notification-detail {
			a {
				color: #3f65f1 !important;
			}
		}
		.action-status {
			margin-top: 50px;
			margin-bottom: 20px;
			h6 {
				display: inline;
			}
			.filters {
				display: inline;
				.filter-menu {
					border-radius: 6px;
					padding: 4px 6px;
				}
				.list-filters {
					border-radius: 6px;
				}
			}
		}

		.ai {
			margin-left: 1.5rem;
			background-color: #f0e9f5;
			padding: 6px 6px;
			border-radius: 6px;
			color: #7c4aa9;
			font-size: 10pt;
			display: flex;
			align-items: center;
			width: max-content;
			position: relative;
			display: inline-block;
			border: 0.005rem solid #7c4aa9;
			svg {
				margin-right: 5px;
			}
		}

		table.recommended-ingredients {
			colgroup col {
				&.score {
					width: 50px;
				}
			}
			thead {
				tr td {
					text-align: center;
				}
			}
			td {
				border-top: none;
				padding: 6px 0;
				vertical-align: middle;
				img {
					height: 24px;
					border-radius: 6px;
				}
			}
			tbody {
				tr td {
					border-bottom: 1px dashed #e1ecf3;
					text-align: center;
					&:last-of-type {
						text-align: end;
						width: max-content;
					}
					&:first-of-type {
						text-align: start;
					}
					&.rec-ingredient-name {
						text-align: start;
					}
				}
				tr:last-of-type td {
					border-bottom: none;
				}
			}
		}
	}
	&.regulation-notification {
		width: max-content;
		max-width: 1000px;
	}
}
