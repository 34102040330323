.image-upload-wrapper {
    .image-upload {
        width: 186px;
        height: 80px;
        border: dashed 1px var(--bluey-grey);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--very-light-blue);

        div.spinner-border, div.spinner-grow {
            position: absolute;
        }
        
        img {
            width: 100%;
            height: 100%;
        }
        svg {
            color: var(--bg-slate);
        }
        &.circle {
            width: 80px;
            border-radius: 80px;

            img {
                border-radius: 100%;
            }
        }

        &.square {
            width: 80px;
        }
    }
}


