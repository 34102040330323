.modal-dialog.create-recommendation {
	max-width: 50%;
	width: 50%;
	.modal-body {
		.form-group {
			margin-bottom: 0;
		}
		.input-group {
			border: 1px solid #e1ecf3;
			padding: 6px 12px;
			border-radius: 6px;
			height: 40px;
			align-items: center;
			&:hover {
				border-color: hsl(0, 0%, 70%);
			}
			&.active {
				border: 1px solid;
				border-image-source: linear-gradient(269.65deg, #c747f4 0%, #395efa 100%);
			}
		}
		ul {
			margin-top: 20px;
			padding-left: 0;
			max-height: 300px;
			overflow-y: scroll;
		}
		li.ingredient-search-item {
			min-height: 40px;
			text-overflow: ellipsis;
			margin: 5px 5px;
			max-width: 100%;
			white-space: pre-wrap;
			display: flex;
			flex-flow: row nowrap;
			position: relative;
			justify-content: flex-start;
			border: solid 1px var(--powder-blue);
			border-radius: 6px;
			align-items: center;
			a {
				margin-left: 20px;
			}
			span.journey-ai-tag {
				margin-left: auto;
				margin-right: 0;
				width: max-content;
				padding: 3px 6px;
				font-size: 9pt;
				border-radius: 6px;
				background-color: #7d4aa91e;
				color: #7c4aa9;
			}
			img {
				width: 24px;
				height: 24px;
				border-radius: 6px;
			}
			.rounded-circle {
				position: relative;
				margin-right: 10px;
				margin-left: 25px;
				visibility: visible;
				display: flex;
				width: max-content;
				height: max-content;
				align-items: center;
				justify-content: center;
				padding: 6px;
				@extend .custom-shadow;
				top: 0;
				transition: top 0.2s ease-in-out;
				&:hover {
					top: -1px;
					svg {
						stroke: #fff !important;
						color: #fff !important;
					}
					&.remove {
						background-color: #e85a73;
					}
					&.add {
						background-color: #3f65f1;
					}
				}
			}

			&:hover {
				.rounded-circle {
					visibility: visible;
					cursor: pointer;
				}
			}
		}
		.rec-categories {
			display: flex;
			flex-wrap: wrap;
			.tag {
				padding: 6px;
				border-radius: 6px;
				border: 1px solid #e1ecf3;
				margin: 5px 5px 0 0;
			}
		}
		.count {
			color: #3f65f1;
		}
		.custom-tag-input {
			width: 100px !important;
			border: none;
			width: max-content;
		}
	}
}
