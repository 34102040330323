//Buttons
.btn {
	position: relative;
	transition: $transition-base;
	border-radius: 6px;
	font-weight: 600;
	letter-spacing: 0.5px;
	&:hover {
		@include box-shadow($btn-hover-box-shadow);
		transform: translateY(-1px);
		-webkit-transform: translateY(-1px);
		-moz-transform: translateY(-1px);
		-o-transform: translateY(-1px);
	}
	&:focus,
	&.focus {
		outline: 0;
		box-shadow: none;
	}
}
.btn-circle {
	border-radius: 100%;
	width: 40px;
	height: 40px;
	padding: 10px;
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		@include box-shadow($btn-active-box-shadow);

		&:focus {
			@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
		}
	}
	&.btn-sm {
		width: 35px;
		height: 35px;
		padding: 8px 10px;
		font-size: 14px;
	}
	&.btn-lg {
		width: 50px;
		height: 50px;
		padding: 14px 15px;
		font-size: 18px;
		line-height: 23px;
	}
	&.btn-xl {
		width: 70px;
		height: 70px;
		padding: 14px 15px;
		font-size: 24px;
	}
}

.btn-rounded {
	border-radius: 60px;
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		@include box-shadow($btn-active-box-shadow);

		&:focus {
			@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
		}
	}
	&.btn-lg {
		padding: 0.75rem 1.5rem;
	}
	&.btn-sm {
		padding: 0.25rem 0.75rem;
		font-size: 12px;
	}
	&.btn-md {
		padding: 12px 35px;
		font-size: 16px;
	}
}
// Brand buttons

@each $color, $value in $brand-colors {
	.btn-#{$color} {
		@include button-variant($value, $value);
	}
}

.btn-group,
.input-group {
	.btn {
		margin-right: 0;
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-o-transform: translateY(0);
	}
}

.button-group .btn {
	margin-bottom: 5px;
}

.no-button-group .btn {
	margin-bottom: 5px;
	margin-right: 0px;
}

.btn .text-active {
	display: none;
}

.btn.active .text-active {
	display: inline-block;
}

.btn.active .text {
	display: none;
}
.btn-yellow {
	color: white;
	background-color: #f4a850;
	border-color: #f4a850;
}

.btn-outline-powderblue {
	padding: 8px 11px;
	margin: 10px;
	min-width: 7%;
	background-color: #fff;
	border-radius: 6px;
	display: flex;
	border: 2px solid $powder-blue;
	justify-content: center;
	align-items: center;
	p {
		margin-bottom: 0;
		font-weight: 600;
		color: $battleship-grey;
	}
	i {
		color: #3f65f1;
		margin-right: 7px;
	}

	@media only screen and (max-width: 720px) {
		min-width: 40%;
	}
	&:hover {
		color: #fff;
		background-color: lighten($powder-blue, 10%);
		border-color: $powder-blue;
	}
	&:active,
	&:focus {
		color: #fff !important;
		background-color: $powder-blue !important;
		border-color: darken($powder-blue, 10%) !important;
		box-shadow: none !important;
	}
}

.btn-adder {
	@extend .btn-add;
	&:hover,
	&:not(:disabled):not(.disabled):active {
		background-color: $bg-blue !important;
		color: white !important;
	}
}

.btn-search {
	@extend .btn-add;
	&:hover,
	&:not(:disabled):not(.disabled):active {
		background-color: $bg-green !important;
		color: white !important;
	}
	color: $bg-green !important;
}

// .btn-outline-secondary:hover,
// .btn-outline-success:hover,
// .btn-outline-warning:hover,
// .btn-outline-danger:hover {
//   color: #fff !important;
// }

.edit-btn-success {
	&:hover {
		color: black;
	}
}

.btn-permission-toggle {
	&.btn-primary {
		background-color: #cedbe9;
		border-color: #cedbe9;
		color: #2c3e50;
	}
}

// Standard styles for a view button
.std-view-btn.btn {
	background-color: #fff;
	border-color: #e1ecf3;
	color: #7a8999;
	box-shadow: none;
	&:hover,
	&:not(:disabled):not(.disabled):active {
		background-color: #3f65f1;
		border-color: #3f65f1;
		color: #fff;
	}
}

.std-submit-btn.btn {
	background-color: #0ed7ac;
	border-color: #0ed7ac;
	color: #fff;
	box-shadow: none;
	&:hover,
	&:not(:disabled):not(.disabled):active {
		background-color: #0ed7ac;
		border-color: #0ed7ac;
		color: #fff;
	}
}

.std-reset-btn.btn {
	background-color: #e85a73;
	border-color: #e85a73;
	color: #fff;
	box-shadow: none;
	&:hover,
	&:not(:disabled):not(.disabled):active {
		background-color: #e85a73;
		border-color: #e85a73;
		color: #fff;
	}
}

.btn-duplicate {
	align-self: flex-end;
	margin-left: auto;
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #3f65f1;
	width: 40px !important;
	height: 40px !important;
	&:hover {
		background-color: #3f65f1;
		color: #fff;
	}
}

.btn-compare {
	align-self: flex-end;
	margin-left: auto;
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #3f65f1;
	width: 40px !important;
	height: 40px !important;
	&:hover {
		background-color: #3f65f1;
		color: #fff;
	}
}
