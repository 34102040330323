.img-ghost {
	@extend .loading-gradient;
}

.recent-img-ghost {
	height: 100px;
	width: 100px;
	border-radius: 6px;
	@extend .loading-gradient;
}

.recent-img-slider-ghost {
	height: 115px;
	width: 115px;
	border-radius: 6px;
	@extend .loading-gradient;
}

.dash-recommendation-img-ghost {
	height: 50px;
	width: 50px;
	border-radius: 6px;
	@extend .loading-gradient;
}

.table-img-ghost {
	height: 40px;
	width: 40px;
	border-radius: 6px;
	@extend .loading-gradient;
}
