div.loader {
    
    .loader__overlay {
        background: #f6f6f6;
        opacity: 0.4;
        min-height: 200px;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0%;

        .fa-spin {
            position: absolute;
            top: 20%;
        }
    }
    &.full {
        .loader__overlay {
            min-height: none;
            height: 100vh;
            min-width: 100vh;
            .fa-spin {
                top: 50%;
            }
        }
    }
}