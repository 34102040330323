@import '../../layout/filters'; // Styles for notification filter menu

.notifications-page {
  thead, table, th { 
    border-top:none !important; 
    // border-bottom: none !important;
    // tr:first-of-type {
    //   border-top: none !important;
    // }
  }

  // thead {
  //   background-color: #e1ecf37e;
  //   // box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
  //   margin-bottom: 15px !important;
  //   th:first-of-type {
  //     border-radius: 6px 0 0 6px !important;
  //   }
  //   th:last-of-type {
  //     border-radius: 0 6px 6px 0 !important;
  //   }
  // }

  .page-title {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #273f52;
    margin-bottom: 0px;
  }

  table {
    tr {
      align-items: center;
      td {
        vertical-align: middle;
      }
      .icon {
        width: 50px;
      }

      .date {
        width: 100px;
      }
      .action {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        .btn {
          width: 140px;
          float: right;
        }
      }
      .notification-page-bubble {
        position: absolute;
        left: 40px;
        margin-left: 0px;
        margin-top: -7px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--pinkish);
      }
    }
    .notification-unread {
      background-color: #f5f5f5;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #273f52;
      width: 100px;
    }

    .description {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #657280;
      max-width: 200px;
    }
    .notification-progress {
      width: 200px;
    }
    }

  }


