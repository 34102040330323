.button-top {
  @extend .font-weight-semibold;
  position:absolute;
  top:0;
  left:0;
  transform: translate(15%, -50%) !important;
}

textarea {
  @extend .border-radius-6;
  resize: none;
  border: 2px solid $powder-blue;
}

.labels {
  max-width: 1350px;
}

.page-content .container-fluid {
  .blurred-feature {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: -15px;
    left: 0;
    // background-color: #2c3e50;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.under-construction {
    z-index: 1050;
    position: absolute;
    width: 500px;
    background-color: #fff;
    padding: 15px;
    border-radius: 6px;
    margin: 15vh 30vw;
    box-shadow: 0 10px 24px rgba(62, 57, 107, 0.1);
    span {
      display: flex;
      align-items: center;
      justify-self: left;
      border-bottom: 1px solid  #e1ecf3;
      padding-bottom: 10px;
      width: 100%;
      font-size: 14pt;
      margin-bottom: 10px;
      svg {
        margin-right: 15px;
      }
    }
    a {
      color: #3f65f1 !important;
      cursor: pointer;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      height: 300px;
      width: 300px;
    }

}

