.coming-soon {
    position: absolute; 
    height: 100%;
    width: 100%; 
    background-color: rgba(255, 255, 255, 0.479);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    p {
        z-index: 101;
        background-color: #e1ecf3;
        border-radius: 12px;
        padding: 6px 12px;
        font-size: 12pt;
    }
}