.recent-item {
	cursor: pointer;
	text-align: center;
	img {
		position: relative;
		top: 0;
		transition: top 0.1s ease-in-out;
		height: 115px;
		width: 115px;
		border-radius: 6px;
		@extend .custom-shadow;
	}
	.starred {
		position: absolute;
		background-color: #fff;
		border-radius: 50%;
		z-index: 5;
		padding: 5px;
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		@extend .custom-shadow;
		left: 115px;
		top: -5px;
		svg {
			fill: #f4a85085;
			color: #f4a850;
		}
	}
	h5 {
		margin-top: 10px;
		&.ghost {
			width: 115px;
			height: 20px;
			@extend .loading-gradient;
			border-radius: 6px;
		}
	}
	&:hover {
		img {
			@include box-shadow($btn-hover-box-shadow);
			top: -1px;
		}
	}
}
