.ingredients {
	.title {
		display: flex;
		h3.count {
			color: #6a8dec;
			margin-left: 5px;
		}
	}
	.all-ingredients-container {
		// box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.08);
		border-radius: 10px;
		padding: 0 0px 20px;
		.display-options {
			margin-right: -2px;
			margin-top: 15px;
		}
		.pagination-wrapper {
			margin-right: 15px;
			margin-top: 5px;
		}
	}
}

.ingredient-item {
	width: 100%;
	margin-bottom: 0;
	cursor: pointer;
	@extend .custom-shadow;
	border-radius: 10px;
	padding: 10px 15px;

	&:hover {
		@include box-shadow($btn-hover-box-shadow);
	}

	.ingredient-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 170px;
	}

	&.mini {
		height: 60px;
		width: 100%;
		border: 1px solid #d1ddea;
		cursor: default;
		padding: 10px;
		box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
		&:hover {
			box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
		}
		svg {
			display: none;
		}
		.info {
			.ingredient-image {
				background-size: cover;
				height: 40px;
				width: 40px;
			}

			.ingredient-detail {
				display: flex;
				justify-content: space-between;
				align-items: center;
				h5 {
					white-space: nowrap;
					width: 400px;
					overflow: hidden;
					text-overflow: ellipsis;

					&:hover {
						overflow: visible;
					}
				}
				h6.portfolio-ingredient {
					background-color: #f4a850ad;
					border-radius: 6px;
					padding: 8px 12px;
					margin-bottom: 0;
					color: #fff;
					i {
						margin-right: 5px;
					}
				}
				.percent {
					color: #4167f2;
					margin-bottom: 0;
					font-weight: 600;
				}
			}
		}
		div:last-child {
			display: none;
		}
	}

	svg {
		position: absolute;
		top: 15px;
		right: 15px;
	}

	div.info {
		display: flex;
		align-items: center;
		.ingredient-image {
			background-size: cover;
			height: 75px;
			width: 100px;
		}

		.ingredient-detail {
			padding: 0 10px;
			width: 100%;
			p.name {
				font-weight: bolder;
				color: #3f5565;
				font-size: 1.1em;
				margin-bottom: 1px;
			}

			.ingredient-rate {
				width: 90%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				p {
					font-weight: 700;
					font-size: 0.99em;
				}
				p.price {
					color: #657280;
				}

				p.rank {
					color: #f5ab56;
				}
			}
		}
	}
}
