.advanced-search-filter-container {
	background-color: #e1ecf3;
	border-radius: 6px;
	padding: 12px 12px;
	display: flex;
	align-items: center;
	color: #2c3e50;
	margin-right: 15px;
	margin-top: 25px;
	.filters-title {
		justify-self: flex-start;
		margin-left: 0;
		display: flex;
		align-items: center;
		svg {
			margin-right: 10px;
		}
		h6 {
			margin-bottom: 0;
			white-space: nowrap;
		}
	}

	.advanced-search-filters {
		margin-left: 15px;
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: space-around;
		.filter {
			margin-right: 15px;
			display: flex;
			align-items: center;
			h6 {
				margin-right: 10px;
				margin-bottom: 0;
			}
		}
	}
}
