// Contextual backgrounds
@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
}

@mixin bg-gradient-variant($parent, $color) {
  #{$parent} {
    background: linear-gradient(87deg, $color 0, adjust-hue($color, 25%) 100%) !important;
  }
}

@mixin outline-box($name, $color) {
  &-outline-#{$name} {
    border: 1px solid $color;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    color: $color;
    font-weight: 600;
    border-radius: 6px;
  }
}

@mixin box($name, $color) {
  &-#{$name} {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: $color;
    color: white;
    font-weight: 600;
    border-radius: 6px;
  }
}
