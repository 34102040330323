.recommendation-actions {
	padding: 0;
	.nav-back {
		margin-bottom: 20px;
	}

	.actions-body {
		.action-section {
			margin: 15px 0 !important;
			display: flex;
			flex-direction: column;
			ul {
				list-style-type: none; /* Remove bullets */
				i {
					color: #f4a850;
					margin-right: 10px;
				}
				a {
					color: #3f65f1;
					cursor: pointer;
				}
			}
			p,
			ul,
			table {
				padding-left: 15px;
			}
		}
	}
}
