.dashboard-personas {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    height: 150px !important;
    .card-body {
        height: 100%;
        padding: 20px 0;
    }
    header {
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
            margin-right: 15px;
            color: #d2bce6;
        }
        h4 {
            display: flex; 
            align-items: center;
        }
    }

    .actions {
        margin-top: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        bottom: 0;
        .view {
            opacity: .75;
            padding: 6px;
            width: 50%;
            text-align: center;
            position: relative;
            top: 0;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            &.preferences {
                background-color: #e1ecf3;
            }
            &.packaging {
                background-color: #d2bce6;
                color: #fff;
            }
            &:hover {
                opacity: 1;
                top: 1px;
            }
        }
    }
}