.filters {
    margin: 19px 0px 0px;
    .filter-title {
      font-size: 12pt;
      margin-right: 20px;
      svg {
        margin-right: 5px;
      }
      align-self: flex-start;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    .filter {
      display: inline-flex;
      height: 40px;
      .filter-menu {
        align-self: flex-start;
        margin-left: 15px;
        padding: 3px 10px;
        cursor: pointer;
        display: inline-block;
        white-space: nowrap;
        overflow-x: auto;
        * {
          display: inline-block
        }
        svg {
          margin-left: 5px;
        }
        border-radius: 4px;
        border: 1px solid #cddae8;
        &:hover {
          background-color: #e1ecf3a2;
        }
        &.active {
          background-color: #3f65f1;
          border: 1px solid #3f65f1;
          color: #fff;
        }
      }
      .list-filters {
        position: absolute;
        z-index: 100;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #e1ecf3;
        box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
        list-style-type: none;
        padding: 0;
        margin: 35px 0 0 15px;
        max-height: 200px;
        overflow-y: scroll;
        li {
          padding: 5px 30px 5px 15px;
          border-bottom: 1px solid #e1ecf380;
          cursor: default;
          &:hover, &.active {
            background-color: #e1ecf3a2;
          }
        }
      }
    }
  }