.product-overview {
    .card {
        @extend .border-radius-12;
        height: 190px;
        width: 100%;
        flex: 1 0 auto;
    }
    .starred {
        box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
        background-image: linear-gradient(to bottom, #fad388, $bg-yellow);
        width: 134px;
        justify-content: center;
    }
    .row {
        margin: 0;
        display: flex;
        height: max-content;
    }
    .card-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px;
    }
    .card-title {
        display: flex;
        align-items: center;
    }
    .fa-star {
        font-size: 22px;
    }
    .card-subtitle {
        font-size: 1rem;
        font-weight: normal;
        color: $battleship-grey;
        a {
            font-size: 0.6875rem;
            color: #fff;

            &:hover {
                color: whitesmoke;
            }

            &::after {
                content: ">>";
                margin-left: 1px;
            }
        }
    }

    .notifications {
        height: 100%;
        background-image: linear-gradient(to bottom, #e1ecf3, #cedbe9);
        // background-image: linear-gradient(to bottom, #739ef9, $bg-blue);
        color: #2c3e50;
        min-height: 220px;
        min-width: 260px;
        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            div {
                display: flex;
                align-items: center;
                width: 100%;
                h1 {
                    margin-right: 20px;
                }
                a {
                    margin-top: 15px;
                    margin-left: auto;
                    color: #2c3e50;
                    font-size: 14px;
                }
            }
            .account-completion {
                display: flex;
                flex-direction: column;
                .progress-bar {
                    background-color: #0ed7ac !important;
                    height: 100%;
                }
                p {
                    font-size: 14px;
                    margin: 0 auto 0 0;
                }
            }

        }
    }

    .product-chart {
        width: 361px;
    }
    .active-product {
        display: flex;
        flex: 1 1 0;
        min-width: 0;
        width: 100%;
        max-height: 200px;
        height: 200px;
        box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
        background-image: linear-gradient(to bottom, #739ef9, $bg-blue);

        .chart {
            flex: 1 1 auto;
        }

        .card-title,
        .card-subtitle {
            color: #fff;
        }
    }

    .concept-product {
        display: flex;
        height: 200px;
        flex: 1 1 0;
        min-width: 0;
        // width: 100%;
        box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
        background-image: linear-gradient(to bottom, #fad388, $bg-yellow);

        .chart {
            flex: 1 1 auto;
        }

        .card-title,
        .card-subtitle {
            color: #fff;
        }
    }
    .data-tooltip {
        border-radius: 4px;
        padding: 7px 10px;
        font-size: 12px;
        background: #3a3a48;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        color: #fff;;
        text-align: left;
        white-space: nowrap;
        margin: 5px;
    }
}

.title-container {
    display: flex;
    align-items: center;
    margin-top: 27px;
}

.inactive {
    opacity: 0.5;
}