.subscription {
	.subscription-list {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	}

	.subscription-item {
		@extend .custom-shadow;
		margin-right: 20px;
		height: 300px;
		min-width: 215px;
		border-radius: 10px;
		&:last-child {
			margin-right: 0;
		}

		.name {
			display: flex;
			justify-content: center;
			align-items: center;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
			p {
				color: #fff;
				font-weight: 600;
				margin-bottom: 0;
				font-size: 16px;
			}
			height: 15%;
		}
		.details {
			background-color: #fff;
			height: 85%;
			// box-shadow: 3px 5px 20px 7px rgba(0, 0, 0, 0.08);
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			padding: 15px;
			.plans {
				display: flex;
				flex-flow: row nowrap;
			}

			.plan {
				padding: 10px;
				.price {
					margin-left: 5px;
					color: "#273F52";
					font-size: 36px;
					font-weight: 600;
				}
				ul {
					padding-inline-start: 30px;
				}
				li {
					color: $battleship-grey;
					font-size: 14px;
				}
			}
		}
	}
}

.subscription-modal {
	@media (min-width: 576px) {
		&.modal-dialog {
			max-width: 700px;
			margin: 1.75rem auto;
		}
	}
}
.checkout-container {
	.csv-checkout-options {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.btn {
			margin: 15px;
			width: 50%;
		}
		p {
			margin-top: 20px;
			text-align: start;
			align-self: center;
		}
	}
	.back-btn {
		margin-bottom: 10px;
		cursor: default;
		svg {
			position: relative;
			left: -2px;
			transition: left 0.2s ease-in-out;
		}
		&:hover {
			svg {
				left: -4px;
			}
		}
	}
	.info-container {
		margin-bottom: 16px;
		border-radius: 6px;
		padding: 20px 20px 20px 20px;
		font-size: 10pt;
		line-height: 1rem;
		background-color: #e1ecf3;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-shadow: 0 6px 12px 0 rgba(57, 57, 57, 0.2);
		width: 100%;
		.info-icon {
			margin-right: 20px;
			padding: 0;
			min-height: 24px !important;
			min-width: 24px !important;
			color: #fff;
			fill: #3f65f1;
		}
	}
	.btn.confirm {
		margin-top: 15px;
		background-color: #0ed7ac;
		color: #fff;
		padding: 6px 12px;
		border-color: #0ed7ac;
		display: inline-block;
		width: fit-content;
	}
	.payment-header {
		box-shadow: 0 5px 5px -5px rgba(57, 57, 57, 0.2);
		border-radius: 6px;
		background-color: #e1ecf3;
		color: #2c3e50;
		font-weight: 200;
		padding: 6px 12px;
		display: inline-block;
		margin: 15px 0px;
		align-self: flex-start;
	}
	.checkout-subscription-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 20px;
		p {
			display: inline;
			bottom: 0;
			margin-bottom: 0;
		}
		.checkout-select-subscription {
			margin-right: 15px;
			width: 300px;
			.select-subscription__control {
				border-radius: 6px;
				border: 2px solid #cedbe9;
				height: 40px;
			}
			.select-subscription__option {
				background-color: #fff;
				color: #2c3e50;
				&:active {
					background-color: #3f65f1;
					color: #fff;
				}
			}
		}
	}

	.paylater-confirmation {
		display: flex;
		flex-direction: column;
	}
	.checkout-form {
		.checkout-form-input {
			width: 100%;
			height: 40px;
			border-radius: 6px;
			padding-left: 15px;
		}
		.checkout-card-input {
			padding-top: 10px;
			border-radius: 6px;
			border: 2px solid #cedbe9;
			height: 40px;
			padding: 10px 15px 0 15px;
		}
		.checkout-form-submit {
			margin-top: 15px;
			margin-bottom: 15px;
			color: #fff;
			background-color: #3f65f1;
			border-radius: 6px;
			padding: 10px;
		}
		.btn.complete-payment {
			margin-top: 15px;
			margin-bottom: 15px;
			background-color: #0ed7ac;
			color: #fff;
			padding: 6px 12px;
			border-color: #0ed7ac;
		}
	}
}

.modal-header-content {
	.close-modal {
		float: right;
		right: 0;
	}
}

.subscription-options {
	display: flex;
	flex-direction: column;
	align-items: center;
	h3 {
		align-self: center;
		background-color: #fff;
		text-align: center;
		padding: 12px;
		border-radius: 6px;
	}
	.row {
		justify-content: space-evenly;
		align-items: center;
		.card {
			border-radius: 8px;
		}
		.option {
			flex-grow: 1;
			background-color: #fff;
			position: relative;
			display: flex;
			flex-direction: column;
			max-width: 250px;
			width: 150px;
			height: 375px;
			align-items: center;
			justify-content: flex-start;
			ul:first-of-type {
				list-style: none;
				line-height: 30px;
				padding-left: 20px;
				margin-bottom: 34px;
				margin-top: 35px;
				li {
					svg {
						margin-right: 10px;
						color: #0ed7ac;
					}
				}
				ul {
					padding-left: 50px;
					list-style-type: disc;
					margin: 0;
				}
			}
			.info {
				display: flex;
				align-items: center;
				justify-content: center;
				justify-self: center;
				padding: 6px 12px;
				width: fit-content;
				font-size: 10pt;
				border-radius: 12px;
				cursor: pointer;
				svg {
					margin-right: 10px;
				}
				position: relative;
				top: 0;
				transition: all 0.2s ease-in-out;
				&:hover {
					top: -2px;
					background-color: #e1ecf3;
					padding: 6px 12px;
				}
			}
			p {
				text-align: center;
				font-size: 12pt;
				margin-top: 15px;
				margin-bottom: 0;
				&.price {
					min-height: 75px;
					max-height: 75px;
				}
			}
			h4 {
				margin-top: 40px;
			}
			img {
				height: 100px;
				width: 100px;
				position: absolute;
				top: -50px;
			}
			hr {
				max-width: 90%;
			}
		}
	}
	.view-features {
		justify-self: center;
		border-radius: 25px;
		color: #3f65f1;
		padding: 6px 12px;
		width: max-content;
		display: flex;
		align-items: center;
		cursor: pointer;
		svg {
			margin-left: 10px;
		}
		position: relative;
		top: 0;
		transition: top 0.2s ease-in-out;
		&:hover {
			top: 2px;
		}
	}
}
