.dashboard-packaging {
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	height: 150px !important;
	.card-body {
		height: 100%;
		padding: 20px 0;
	}
	header {
		padding: 10px 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		h3.ghost {
			width: 100%;
			height: 28px;
			border-radius: 6px;
			@extend .loading-gradient;
		}
		i {
			margin-right: 15px;
			font-size: 24pt;
			color: #7593ff;
		}
		h4 {
			display: flex;
			align-items: center;
		}
	}

	.actions {
		margin-top: auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		position: absolute;
		bottom: 0;
		.view {
			opacity: 0.75;
			padding: 6px;
			width: 50%;
			text-align: center;
			position: relative;
			top: 0;
			transition: all 0.2s ease-in-out;
			cursor: pointer;
			&.preferences {
				background-color: #e1ecf3;
			}
			&.packaging {
				background-color: #3f65f1;
				color: #fff;
			}
			&:hover {
				opacity: 1;
				top: 1px;
			}
		}
	}

	.mini-table-wrap {
		margin: 15px 0;
		max-height: 180px;
		overflow-y: scroll;
		width: 100%;
		table {
			height: 100%;
			cursor: pointer;
			tbody {
				display: block;
				overflow-y: auto;
				td {
					padding-left: 0;
					border: none;
					position: relative;
					left: 0;
				}
				tr:hover {
					td {
						left: 2px;
						background-color: #e1ecf360;
					}
				}
			}
		}
	}
}
