.recent-ghost {
	// Ghost styles
	h5.ghost {
		padding: 10px 0;
		margin-top: 10px;
		margin-bottom: 0;
		// background-color: #8fa1b311;
		@extend .loading-gradient;
	}

	img {
		@extend .recent-img-ghost;
	}
}
