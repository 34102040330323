.product-types {
    padding: 20px;
    h5 {
        font-weight: 8;
    }
    overflow: visible;

    span.add {
        display: none;
        font-size: 1.5em;
        position: static;
        align-items: baseline;
        right: 30px;
        top: 25%;
        p {
            margin-right: 5px;
        }
    }
}

.product-type-list {
    display: flex;
    align-items: center;
    overflow:auto;
    flex-wrap: wrap;
    .product-type-item {
        @extend %items;
        p {
            margin-bottom: 0;
            font-weight: 600;
        }
        i {
            color: #3f65f1;
            margin-right: 7px;
        }

        @media only screen and (max-width: 720px) {
            min-width: 40%;
        }
    }
}
