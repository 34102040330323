
.product-ingredient-item {
  width: 258px;
  height: 40px;
  border: 1px solid #cedbe9;
  border-radius: 6px;
  padding: 8px 11px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;

  .ingredient-image {
    background-size: cover;
    height: 24px;
    width: 29px;
  }

  .ingredient-name {
    flex: 1;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    color: #273f52;
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ingredient-percentage {
    color: #3f65f1;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: right;
  }

}