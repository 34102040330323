.search-container {
	.form-group {
		border: 2px solid #cedbe9;
		padding: 0.5rem 1rem;
		padding-right: 0;
		border-radius: 5px;
		.input-group {
			display: flex;
			align-items: center;
		}
		.form-control {
			display: block;
			padding: 0;
			font-size: 0.875rem;
			line-height: 21px;
			height: 30px;
			color: #525f7f;
			background-color: #fff;
			background-clip: padding-box;
			border: 2px solid #cedbe9;
			border-radius: 6px;
			border-radius: 2px;
			box-shadow: none;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		}
	}
	.search-results {
		display: flex;
		flex-direction: column;
		padding: 1.25rem;
		.package-item-mini {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 60px;
			width: 100%;
			border: 1px solid #d1ddea;
			cursor: default;
			padding: 10px;
			box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
			margin: 0.75rem 0;
			border-radius: 10px;
			&.active {
				border: 2px solid #3f65f1;
			}
			img {
				height: 40px;
				width: 40px;
				margin-right: 15px;
				border-radius: 6px;
			}
			#matched {
				margin-left: auto;
				margin-right: 0;
				justify-self: flex-end;
				font-size: 12px;
				display: flex;
				align-items: center;
				width: max-content;
				border: 1px solid #3f65f1;
				border-radius: 6px;
				color: #fff;
				background-color: #3f65f1;
				padding: 3px 6px;
				text-align: center;
			}
		}
	}
}
