// font-weights
.font-bold {
	font-weight: $font-weight-bold;
}
.font-normal {
	font-weight: $font-weight-normal;
}
.font-light {
	font-weight: $font-weight-light;
}
.font-medium {
	font-weight: $font-weight-medium;
}

// font sizing
.font-16 {
	font-size: 16px;
}
.font-12 {
	font-size: 12px;
}
.font-11 {
	font-size: 11px;
}
.font-14 {
	font-size: 14px;
}
.font-16 {
	font-size: 16px;
}
.font-10 {
	font-size: 10px;
}
.font-18 {
	font-size: 18px;
}
.font-20 {
	font-size: 20px;
}
.font-22 {
	font-size: 22px;
}
.font-24 {
	font-size: 24px;
}

// Type display classes
.display-5 {
	font-size: $display5-size;
	font-weight: $display5-weight;
	line-height: $display-line-height;
}
.display-6 {
	font-size: $display6-size;
	font-weight: $display6-weight;
	line-height: $display-line-height;
}
.display-7 {
	font-size: $display7-size;
	font-weight: $display7-weight;
	line-height: $display-line-height;
}

.blockquote {
	border-left: 5px solid $primary;
	border: 1px solid $border-color;
	padding: 15px;
}

//text colors
.color-bg-blue {
	color: $bg-blue;
}
.color-bg-yellow {
	color: $bg-yellow;
}

.color-bg-green {
	color: linear-gradient(180deg, #17c9dc 0%, #1dd6ae 100%);
}

//text decoration

.txt_underline {
	text-decoration: underline;
}

.pointer {
	cursor: pointer;
}
