.integration-header {
    box-shadow: 0 5px 5px -5px rgba(57, 57, 57, 0.2);
    padding-bottom: 10px;
}
.csv-integration-container {
    margin-top: 15px;
    display: flex; 
    flex-direction: row; 
    overflow: hidden;
    .instructions-container {
        overflow: scroll;
        height: 100vh;
        width: 60vw;
        line-height: 2.0rem;
        display: flex;
        flex-direction: column;
        padding-right: 10px; 
        h4, h5 {
            margin: 10px 0;
        }
        .info-container {
            margin-right: 50px;
            margin-bottom: 16px;
            border-radius: 6px;
            padding: 20px 20px 20px 20px;
            font-size: 10pt;
            line-height: 1rem;
            background-color: #e1ecf3;
            display: flex; 
            flex-direction: row; 
            align-items: center;
            box-shadow: 0 6px 12px 0 rgba(57, 57, 57, 0.2);
            .info-icon {
                margin-right: 20px;
                padding: 0;
                min-height: 24px !important;
                min-width: 24px !important;
                color: #fff;
                fill: #3f65f1;
            }
        }

        ul {
            padding-left: 50px;
        }
        strong, b {
            padding: 0;
        }
    }
    .integration-actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px;
        padding-right: 10px;
        border-right: 2px solid #cedbe94f;
        .subsection-links {
            .main-list {
                padding: 0;
                list-style-type: none;
                li {
                    margin-top: 3px;
                }
                a {
                    color: #2c3e50;
                    &:hover {
                        color: #3f65f1;
                    }
                }
                .sub-list {
                    list-style-type: none;
                }
            }
        }
        .manage-btn {
            display: flex;
            height: 40px;
            align-items: center;
            color: #fff !important;
            background-color: #3f65f1!important;
            border: none !important;
            text-align: justify;
        }
        .template-downloads {
            margin-top: 30px; 
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .download-btn {
                // width: 170px;
                background-color:#e1ecf3;
                border: unset;
                display: inline-block;
                text-align: justify;
            }
            .download-selection {
                margin-top: 30px;
                .download-select {
                    width: 300px;
                }
            }

        }
    }
}

.upload-body {
    .upload-type {
        padding: 20px 20px 0px 5px;
    }
    .info-container {
        margin: 16px 0;
        border-radius: 6px;
        padding: 20px 20px 20px 20px;
        font-size: 10pt;
        line-height: 1rem;
        background-color: #e1ecf3;
        display: flex; 
        flex-direction: row; 
        align-items: center;
        box-shadow: 0 6px 12px 0 rgba(57, 57, 57, 0.2);
        div {
            div {
                margin-bottom: 10px;
            }
            p {
                margin: 3px 0;
            }
        }
        .info-icon {
            margin-right: 20px;
            padding: 0;
            min-height: 24px !important;
            min-width: 24px !important;
            color: #fff;
            fill: #3f65f1;
        }
    }
    .clear-uploads {
        top: 0;
        display: flex;
        height: 30px;
        align-items: center;
        span {
            margin-left: 10px;
        } 
    }
    p {
        margin-top: 5px;
        line-height: 1rem;
    }
    .drop-zone {
        border: dashed #cedbe9 3px; 
        border-radius: 12px; 
        height: 300px;
        width: 100%;
        display: flex; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 0;
        transition: top ease 0.2s, border-color ease 0.2s;
        .uploaded-files {
            position: absolute;
            bottom: 0;
            margin-bottom: 15px;
            text-align: center;
            p {
                font-size: 12px;
                padding: 5px 0 0 0;
                position: relative;
            }
        }
        &:hover {
            border-color: #2c3e50;
            top: -2px;
        }
        p {
            margin: auto;
            text-align: center;
            font-size: 16pt;
            padding: 15px;
            line-height: normal;
            cursor: default;
            position: absolute;
            a {
                color: #0ed7ac;
            }
        }
    }
    .upload-success {
        padding: 15px 0px 0px;
        display: flex;
        align-items: center;
    }
    .upload-btn {
        margin-top: 15px;
        margin-right: 15px;
        display: flex;
        height: 40px;
        align-items: center;
        color: #fff !important;
        background-color: #0ed7ac !important;
        border: none !important;
        span {
            margin-left: 15px;
        } 
        &.loading {
            background-color: #0ed7aba2;
        }
    }
    .reset-btn {
        margin-top: 15px;
        margin-right: 15px;
        display: flex;
        height: 40px;
        align-items: center;
        color: #fff !important;
        background-color: #3f65f1 !important;
        border: none !important;
        span {
            margin-left: 15px;
        } 
    }
    .upload-success {
        svg {
            margin-right: 5px;
        }
    }
    .submit-upload {
        display: flex;
        flex-direction: row;
        align-items: center;
        .upload-error-message {
            span {
                margin-left: 5px;
                color: #e85a73;
            }
        }
    }

    .submit-request {
        .message-info {
            font-size: 12pt;
            margin-left: 5px;
        }
        .message {
            width: 100%;
            min-height: 150px;
            padding: 10px;
            border-radius: 6px;
        }
        .upload-error-message {
            span {
                margin-left: 5px;
                color: #e85a73;
            }
        }
    }

    .match-file-container {
        p {
            margin: 15px 5px 5px;
        }
    }
    .selection-error {
        display: flex;
        align-items: center;
        margin-top: 15px;
        color: #e85a73;
        span {
            margin-left: 5px;
            text-align: center;
        }
    }
}

.modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    h3 {
        display: inline;
    }
    .close-modal {
        float: right;
        cursor: pointer;
    }
}
