.side-form {
    position: fixed;
    display: block;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    z-index: 99;
    transition: all 0.5s ease-in-out;
    max-width: 100%;
    overflow: auto;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: #667784;
        height: 80px;
        font-size: 1.2em;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #CEDBE9;
        strong{
            font-size: 1.7em;
        }
        i {
            color: #3F65F1;
            font-size: 1.7em;
            margin-right: 5px;
            &.close {
                color: grey;
            }
        }
    }

    .overlay {
        background: #b6c2ca;
        opacity: 0.7;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
    }

    &.show {
        left: 0;
    }

    .content {
        box-shadow: -20px -3px 20px 13px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        background: #ffffff;
        width: 60%;
        font-size: 14px;
        height: 100%;
        overflow-y: auto;
        color: #ffffff;
        max-width: 100%;
        position: relative;
        display: block;
        position: absolute;
        right: 0;
      
        form {
            padding: 40px;
            label {
                color: grey;
            }
        }

    }
}