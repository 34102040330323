.analytics {
    padding: 0px 20px 20px;
    .chart-header {
        display: flex;
        justify-content: space-between;
        select {
            border-radius: 6px;
        }
    }

    .chart-card {
        box-shadow: 0 0 5px 0 rgba(57, 57, 57, 0.2);
        border-radius: 6px;
        margin-bottom: 20px;
        padding-top: 1.5rem;
        padding-left: 1.5rem
    }

    .stats {
        & > div {
            width: 265px;
            height: 110px;
            border-radius: 10px;
            padding: 20px 20px;
            margin-top: 20px;
            margin-right: 18px;
            box-shadow: 0 2px 10px 0 rgba(57, 57, 57, 0.2);
            &:last-of-type {
                margin-right: 0px;
            }
            span {
                display: flex;
                align-items: baseline;
                p {
                    font-size: 1.8em;
                    margin-bottom: 0;
                    margin-right: 5px;
                }

                i {
                    font-size: 1.5em;
                }
                flex-flow: row nowrap;
            }

            &.ingredient {
                color: #273f52;
                background-image: linear-gradient(#eccc95, #f4a850);
            }
            &.product {
                color: #fff;
                background-image: linear-gradient(#6c97f8, #7593ff);
            }
            &.user {
                color: #273f52;
                background-image: linear-gradient(#91f4e4, #51c5ac);
            }
            &.manufacturer {
                color: #fff;
                background-image: linear-gradient(#a875cb, #7c4aa9);
            }
        }
    }

    .analysis-integration {
        margin-left: 15px;
        animation: blinker 2s linear infinite;
        border-radius: 12px;
        padding: 3px 12px;
        background-color: #89cff05d;
    }
      
    @keyframes blinker {
        50% {
            opacity: 55%;
        }
    }

    .py-graph-container { 
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%; 
        margin-right: 1.5rem;
        position: relative;
        .chart-header {
            position: absolute;
            top: 0;
            width: 100%; 
        }
        .graph {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            img {
                width: 90%;
            }
        }
    }
}
