.rec.rec-ghost {
	p {
		@extend .loading-gradient;
		height: 15px;
		border-radius: 6px;
		width: 50px;
		margin-top: 5px;
	}

	img {
		@extend .dash-recommendation-img-ghost;
	}
}
