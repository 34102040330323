.search-page {
	display: inline-flex;
	flex-direction: row;
	width: 100%;
	.live-search-container {
		padding-top: 20px 0;
		margin-left: 0;
		min-width: 100%;
		display: flex;
		flex-direction: row;
		.ingredient-results {
			width: 33%;
			.display-options {
				width: 100%;
				margin: 0 15px 15px;
				svg {
					margin: 0 5px 0 0;
					float: right;
				}
			}
		}
		.ingredient-results .row {
			&.shared {
				max-height: 50vh;
			}

			overflow-y: scroll;
			.search-card {
				@extend .search-card;
			}
		}
		.manufacturer-results {
			&.grid {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}
			.search-card {
				@extend .search-card;
				width: 45% !important;
			}
		}

		.search-card {
			margin: 0px 15px 15px;
			padding: 6px;
			height: 100px;
			width: 45%;
			.card-body {
				padding: 6px 0 6px 0;
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				align-items: center;
				.result-name {
					display: inline;
					margin: 0;
					padding: 0;
					overflow-y: scroll;
					max-height: 50px;
					max-width: 150px;
				}
				img {
					height: 50px;
					width: 50px;
					border-radius: 6px;
				}
				.add {
					margin: 0 0 0 auto;
					background-color: #cedbe9;
					border-radius: 50%;
					min-height: 30px;
					min-width: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					top: 0;
					transition: top 0.2s ease-in-out;
					&:hover {
						box-shadow: 0 1px 2px #2c3e5052;
						top: -1px;
						background-color: $bg-blue;
						svg {
							stroke: #fff;
						}
					}
				}
				.search-details {
					display: flex;
					width: 100%;
					justify-content: space-between;
					align-items: center;
					.country {
						display: flex;
						align-items: center;
						.arrow-down {
							width: 0;
							height: 0;
							border-left: 5px solid transparent;
							border-right: 5px solid transparent;
							border-bottom: 0;
							border-top: 5px solid #2c3e50;
						}
					}
					.country-list {
						position: absolute;
						left: 90%;
						margin-top: 20px;
						background-color: #fff;
						z-index: 15;
						border-radius: 4px;
						@extend .custom-shadow;
						ul {
							padding-left: 5px;
							list-style: none;
						}
					}
				}
			}
		}
		.portfolio-results {
			max-width: 33%;
			h3 {
				text-align: start;
			}
			.portfolio-products,
			.portfolio-ingredients {
				.card {
					min-height: 300px;
					max-height: 300px;
					width: 100%;
					padding: 12px;
					.card-body {
						overflow-y: scroll;
						padding-top: 0;
					}
					.card-title {
						display: flex;
						flex-direction: row;
						align-items: center;
						h4 {
							padding-bottom: 0;
							margin-bottom: 0;
							margin-left: 15px;
						}
						i:before {
							width: 30px !important;
							color: #f4a850;
							font-size: 20px;
						}
						span {
							margin-left: auto;
							margin-right: 15px;
							align-self: flex-end;
						}
					}
					.portfolio-table.table {
						th {
							border: none;
							padding: 5px 16px;
						}
						td {
							vertical-align: middle;
							position: relative;
							.result-tag {
								position: absolute;
								border: 1px solid #fff;
								border-radius: 12px;
								background-color: #3f65f1;
								color: #fff;
								top: 5px;
								left: 5px;
								z-index: 10;
								height: 20px;
								width: 20px;
								text-align: center;
								font-size: 10px;
								display: flex;
								align-items: center;
								justify-content: center;
								&.legend {
									position: relative;
									top: unset;
									left: unset;
									z-index: unset;
								}
							}
						}
						tbody tr:hover {
							background-color: #e1ecf34b;
							cursor: pointer;
						}
						thead tr {
							cursor: default;
						}
						img {
							height: 40px;
							width: 40px;
							border-radius: 6px;
						}
						colgroup {
							.persona-score {
								width: 100px;
							}
							col.img {
								width: 50px;
							}
						}
					}
					.card-footer {
						padding: 5px 0 0 0;
						background-color: transparent;
						.result-tag {
							position: relative;
							border: 1px solid #fff;
							border-radius: 12px;
							background-color: #3f65f1;
							color: #fff;
							height: 20px;
							width: 20px;
							text-align: center;
							font-size: 10px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
		}
		.search-history {
			justify-self: flex-end;
			// max-width: 300px;
			max-height: 400px;
			min-height: 400px;
			.card {
				margin-right: 0;
				margin-left: auto;
				padding: 20px;
				border-radius: 6px;
				width: 300px;
			}
		}
	}
}

.search-result-list {
	margin: 0 30px 0 15px;
	&.small-table {
		margin: 0;
	}
	// border-spacing: 0 15px; for full row color
	border-spacing: 15px;
	border-collapse: separate;
	table-layout: fixed;
	colgroup {
		.m {
			width: 150px;
		}
		.n {
			width: 150px;
		}
		.rec {
			width: 150px;
		}
	}
	.add {
		margin: 0 0 0 auto;
		background-color: #cedbe9;
		border-radius: 50%;
		height: 30px;
		width: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		top: 0;
		transition: top 0.2s ease-in-out;
		&:hover {
			box-shadow: 0 1px 2px #2c3e5052;
			top: -1px;
			background-color: $bg-blue;
			svg {
				stroke: #fff;
			}
		}
	}
	tr {
		margin: 15px 0;
		@extend .custom-shadow;
		border-radius: 6px;
		width: 100%;
		max-width: 100%;
		border-collapse: collapse;
		cursor: pointer;
		td,
		th {
			vertical-align: middle;
			border: none;
			.cost {
				padding: 3px 6px;
				border-radius: 6px;
				background-color: #0ed7ac;
				// background-color: lightgrey;
				font-size: 12px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.38px;
				text-align: center;
				color: #ffffff;
				text-transform: capitalize;
				white-space: nowrap;
				* {
					display: inline-block;
				}
			}
		}
		td.name,
		td.manufacturer {
			white-space: nowrap;
		}
		&.recommend {
			box-shadow: 0 0 10px #2c3e5027;
			div {
				position: relative;
				&.left:after {
					content: "";
					height: 50px;
					width: 4px;
					background: #3f65f1;
					z-index: 1;
					position: absolute;
					left: -16px;
					top: -5px;
					border-radius: 0 6px 6px 0;
				}
				&.right:after {
					content: "";
					height: 50px;
					width: 4px;
					background: #3f65f1;
					z-index: 1;
					position: absolute;
					right: -16px;
					top: -10px;
					border-radius: 6px 0 0 6px;
				}
				&.right.packaging:after {
					content: "";
					height: 50px;
					width: 4px;
					background: #3f65f1;
					z-index: 1;
					position: absolute;
					right: -16px;
					top: -25px;
					border-radius: 6px 0 0 6px;
				}
			}
			td.top-pick {
				width: 150px;
				span {
					background-color: #f0e9f5;
					padding: 6px 12px;
					border-radius: 6px;
					color: #7c4aa9;
					font-size: 9pt;
					display: flex;
					align-items: center;
					width: max-content;
					position: relative;
					display: inline-block;
					border: 0.005rem solid #7d4aa941;
					svg {
						margin-right: 5px;
						fill: #7c4aa9;
					}
				}
			}
		}
	}
	thead {
		th {
			padding-top: 0;
			width: 100%;
			white-space: nowrap;
		}
		tr {
			box-shadow: none;
		}
	}
}
