.user-requests {
    padding: 20px;

    button.refresh {
        margin: 10px 0px;
    }

    td {
        .actions {
            padding: 0 5px;
            button {
                margin: 0 2px;
                i {
                    margin-right: 0 !important;
                }

                &.approve {
                    background-color: #0ed7ac;
                }

                &.reject {
                    background-color: #e85a73;
                }
            }
        }
    }
}
