.user-reports {
  padding: 20px;

  h3 {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1px;
    color: #273f52;
    flex: 1;
  }

  .page-header {
    padding-top: 26px;
    padding-bottom: 18px;
    display: flex;
    align-items: center;
  }

  td {
    // padding-left: 0px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #273f52;
    vertical-align: middle;
    border-top: 0px;
  }

  span {
    padding: 6px;
    border-radius: 7px;

    &.submitted {
      border: 1px solid #f5a84f;
      color: #f5a84f;      
    }

    &.processing {
      border: 1px solid #51c6ac;
      color: #51c6ac;
    }

    &.uploaded {
      border: 1px solid #51c6ac;
      color: #51c6ac;
      background-color: white;
    }

    &.requested {
      border: 1px solid #f4a850;
      color: #f4a850;
      background-color: white;
      white-space: nowrap;
    }
  }
}