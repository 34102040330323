.admin-billing {
    padding: 20px;
    @extend .clean-table;
    table {
        td {
            span {
                padding: 3px 12px;
                border-radius: 12px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &.paid {
                    background-color: #0ed7ab70;
                }
                &.open {
                    background-color: #f4a85067;
                }
            }

        }
    }
    .page-header {
        padding-top: 26px;
        padding-bottom: 18px;
        display: flex;
        align-items: center;
    }
    
}