.h-12 {
	height: 0.8rem !important;
}
.mt-10 {
	margin-top: 10px !important;
}
.ls-5 {
	letter-spacing: 0.5px;
}
.list-style-none {
	list-style: none;
}

.custom-shadow {
	// box-shadow: 0 5px 16px 0 rgba(57, 57, 57, 0.15) !important;
	box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.08);
}

.font-weight-semibold {
	font-weight: 600 !important;
}

.flex-grow-5 {
	flex-grow: 5;
}

.horizantal-rule {
	&::after {
		content: "";
		height: 2px;
		width: 10px;
		background: $bg-slate;
		display: inline-block;
	}
}

.mt-n3 {
	margin-top: -1rem !important;
}

//border
.border {
	//border style
	&-dashed {
		border-style: dashed;
		border-color: $bluey-grey;
	}
	&-width {
		&-2 {
			border-width: 2px;
		}
	}
	//radius
	&-radius {
		&-6 {
			border-radius: 6px;
		}

		&-12 {
			border-radius: 12px;
		}

		&-top-12 {
			border-radius: 12px 12px 0 0;
		}
	}

	//color
	&-color {
		&-powderblue {
			border-color: $powder-blue !important;
		}
	}

	//width
	&-width {
		&-2 {
			border-width: 2px !important;
		}
	}
}

//background
.bg {
	&-very-light-blue {
		background: $very-light-blue;
	}
}

//colors
.color {
	&-nile-blue {
		color: #273f52;
	}
	&-battleship-grey {
		color: $battleship-grey !important;
	}
	&-bg-slate {
		color: $bg-slate;
	}
	&-bg-navg {
		color: $bg-navg;
	}
	&-bg-green {
		color: $bg-green !important;
	}
	&-white {
		color: white !important;
	}
}

//box
.box {
	@include outline-box(success, $bg-green);
	@include outline-box(danger, $pinkish);
	@include outline-box(warning, $bg-yellow);
	@include outline-box(success, $bg-green);
	@include box(secondary, $powder-blue);
}

.clean-table {
	colgroup {
		col {
			&.small-right {
				width: max-content;
			}
		}
	}
	thead {
		tr {
			background-color: #e1ecf37e;
			// Border radius on first and last header items
			th {
				border: none;
				padding: 12px 16px;
				&:first-of-type {
					border-radius: 6px 0 0 6px;
				}
				&:last-of-type {
					border-radius: 0 6px 6px 0;
				}
			}
		}
	}
	tbody {
		td {
			border: none;
			vertical-align: middle;
		}
		tr {
			border-bottom: 1px dashed #cedbe9;
		}
	}
}

.simple-tooltip {
	.tooltip.show {
		opacity: 1;
		top: -15px !important;
		left: -25px !important;
	}
	.tooltip-inner {
		background-color: #ebebec;
		color: #000000;
		border-radius: 12px 0px 12px 12px;
		padding: 16px;
		font-size: 14px;
		line-height: 18px;
		text-align: left;
	}
	.arrow {
		display: none !important;
		width: 0 !important;
		height: 0 !important;
	}
}

.sort-by {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	.dropdown {
		.dropdown-menu {
			padding: 0;
			.dropdown-item {
				cursor: pointer;
				&:active {
					background: none;
					color: #000000;
				}
				&:focus {
					outline: none;
				}
			}
		}
		.label {
			font-weight: 700;
			font-size: 18px;
			line-height: 36px;
		}
		.btn-secondary {
			padding-left: 0;
			padding-right: 2px;
			background: none;
			border: none;
			color: #000000;
			box-shadow: none;
			font-weight: 700;
			font-size: 18px;
			line-height: 36px;
			&:active {
				background: none !important;
				border: none !important;
				color: #000000 !important;
				box-shadow: none !important;
			}
			&:hover {
				transform: none;
				box-shadow: none;
			}
		}
		.down-arrow {
			margin-left: 30px;
		}
	}
}
.journey-ai {
	padding-left: 1.6rem !important;
	.checkbox-input {
		margin-top: 8px;
	}
	.checkbox-label {
		font-weight: 700;
		font-size: 16px;
		line-height: 32px;
		color: #000000;
	}
}
.clear-checkboxes {
	text-decoration: underline;
	color: #000;
	font-size: 16px;
	margin-left: 28px;
	padding: 0;
	background: none;
	border: 0;
	cursor: pointer;
	outline: none;
	&:focus {
		outline: none;
	}
}
