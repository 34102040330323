.manufacturer-ingredients {
    margin-right: 15px;
    .manufacturer {
        min-height: 75px;
        max-height: 75px;
        display: flex;
        flex-direction: row;
        border-radius: 6px; 
        @extend .custom-shadow;
        background-color: #fff;
        width: 100%; 
        padding: 20px;
        margin: 15px 0;
        align-items: center;
        h5 {
            margin-left: 15px; 
            margin-bottom: 0;
        }
        .show {
            min-height: 21px;
            margin-left: auto;
            margin-right: 0; 
            position: relative;
            bottom: 0;
            transition: 0.2s bottom ease-in-out;
            padding: 6px 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            border-radius: 6px; 
            opacity: 1;
            svg {
                margin-left: 5px
            }
            &.inactive {
                border: 1px solid #e1ecf3;
                color: #8fa1b3;
            }
            &:hover, &.active {
                background-color: #3f65f1;
                border-color: #3f65f1;
                @extend .custom-shadow;
                bottom: -1px;
                color: #fff;
            }
        }
    }

}