.product-overview-card {
    // max-height: 625px !important;
    max-height: 525px;
    align-self: flex-start;
    overflow-y: hidden;
    overflow-x: hidden;
    cursor: default;
    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        .chart-wrapper {
            margin-top: 20px;
            height: 200px;
            width: 150%;
            display: flex;
            align-items:center;
            justify-content: center;
            canvas {
                position: relative;
                z-index: 1;
                height: 200px !important; 
                width: 400px !important;
            }
        }

        .comparison-card {
            top: 300px;
            position: absolute;
            width: 100%;
            .comparison {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .stats {
                    padding: 6px 12px;
                    margin-top: 15px;
                    height: 80px;
                    width: 80%; 
                    border-radius: 6px;
                    @extend .custom-shadow;
                    div {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                    &.company {
                        color: #fff;
                        background-image: linear-gradient(#7593ff, #5e7ef5);
                    }
                    &.jf-users {
                        background-image: linear-gradient(to bottom, #e1ecf3, #cedbe9);
                    }
                }
                .percentage {
                    margin-top: 10px;
                    width: 80%;
                    height: 35px;
                    // background-color: #0ed7ac;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #2c3e50;
                }
                .count {
                    display: flex;
                    justify-content: space-evenly;
                    width: 80%;
                    span {
                        display: flex;
                        align-items: center;
                        font-size: 12pt;
                        border-radius: 6px;
                        span {
                            margin-right: 10px;
                            padding: 6px;
                            color: #fff;
                            &.icon-concept {
                                background-color: #7593ff;
                            }
                            &.icon-active {
                                background-color: #0ed7ac;
                            }
                        }
                    }

                }
            }
        }
        .view {
            z-index: 15;
            cursor: pointer;
            margin-top: auto;
            position: absolute;
            bottom: 0;
            padding: 6px 12px;
            height: 35px;
            background-color:#0ed7ac;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: .75;
            transition: all 0.2s ease-in-out;
            color: #fff;
            svg {
                margin-left: 5px;
            }
            &:hover {
                opacity: 1;
                @extend .custom-shadow;
                bottom: -1px;
            }
        }
    }
}