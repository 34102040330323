.product-nutrition {
	&.container {
		margin-top: 1.5rem !important;
		padding: 0;
		margin-bottom: 1.5rem !important;
	}
	h5 {
		margin-bottom: 0;
	}
	hr {
		border-bottom: 2px dashed #e1ecf3;
		border-top: none;
	}
	.nutrient-field {
		margin: 20px 0;
		padding: 6px 12px;
		cursor: default;
		h5 {
			font-weight: lighter !important;
			display: flex;
			.daily-value {
				margin-right: 0;
				margin-left: auto;
				font-size: 0.75rem;
			}
		}
		&.dropdown-style {
			cursor: pointer;
			&:hover {
				@extend .custom-shadow;
				border-radius: 6px;
			}
		}
	}
	.nutrients-header {
		span {
			margin-left: 10px;
			color: #8fa1b3;
		}
	}
	.sub-nutrients {
		// position: absolute;
		z-index: 15;
		border-radius: 6px 0 0 6px;
		background-color: #fff;
		padding: 15px;
		width: 90%;
		overflow-y: scroll;
		// @extend .custom-shadow;
	}
	.total-energy {
		border-radius: 6px;
		padding: 15px;
		background-color: rgb(246, 249, 252);
		display: flex;
		align-items: center;
		// position: absolute;
		width: 100%;
		div {
			position: relative;
			&:last-of-type {
				top: 0;
				padding: 10px;
				background-color: #fff;
				border-radius: 6px;
				transition: top 0.1s ease-in-out;
				&:hover {
					top: -1px;
					@extend .custom-shadow;
				}
			}
		}
		h5 {
			color: #3f65f1;
			&:first-of-type {
				margin-bottom: 5px;
				color: #2c3e50;
			}
		}
	}
}
