.action-button-group {
	display: flex;
	flex-direction: column;
	position: fixed;
	z-index: 11;
	right: 0;
	bottom: 35px;
	transform: translateX(-50%);

	button {
		border: none;
		font-size: 1em;
		margin: 5px;
		height: 52px;
		width: 52px;
		background-color: #e1ecf3;
		box-shadow: none;
	}
	.action-button-row {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		// Precise translate to make alignment same as add button
		transform: translateX(22%);
	}
}

.action-button {
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 50px;
	right: 40px;
	bottom: 40px;

	button {
		color: #3f65f1;
		border: none;
		font-size: 1em;
		margin: 5px;
		height: 40px;
		width: 40px;
		background-color: #e1ecf3;
		box-shadow: none;
	}
}

.tab-navigation {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;

	.nav {
		width: 100%;

		li {
			a {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				color: $battleship-grey;
				font-weight: 400;

				&.active {
					font-weight: 600;
					@extend .color-nile-blue;
				}
			}
		}
	}
}

.btn-add {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #3f65f1;
	border: none;
	text-align: center;
	font-size: 1em;
	font-weight: lighter;
	margin: 5px;
	box-shadow: none;
	border-radius: 40px;
	height: 40px;
	width: 40px;
	background-color: #eaf1f6;
	&:hover {
		background-color: darken(#eaf1f6, 10%);
		color: #3f65f1;
	}
	&:not(:disabled):not(.disabled):active {
		background-color: darken(#eaf1f6, 20%);
		color: #3f65f1;
	}
}
