div.integration-simple {
	height: 40px;
	width: max-content;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

div.integration-item {
	@keyframes blink {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0.7;
		}
		100% {
			opacity: 1;
		}
	}
	img.inactive {
		animation: blink 1s;
		animation-iteration-count: infinite;
		-webkit-transition: all 1s ease-in-out;
		-o-transition: all 1s ease-in-out;
		-ms-transition: all 1s ease-in-out;
		transition: all 1s ease-in-out;
		-webkit-animation: blink normal 2s infinite ease-in-out;
		-ms-animation: blink normal 2s infinite ease-in-out;
		animation: blink normal 2s infinite ease-in-out;
	}
}

div.integration-simple__items {
	border-radius: 6px;
	height: 40px;
	border: solid 1px var(--powder-blue);
	width: 90%;
	padding: 5px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	p {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 2px;
		color: var(--battleship-grey);
		margin-bottom: 0;
	}

	img {
		width: 20;
		height: 25px;

		&.inactive {
			filter: grayscale(1);
		}
	}
}
