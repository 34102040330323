.products {
    .title {
        display: flex;
        align-items: center;
        margin-top: 5px;
        .filter-buttons {
            margin-left: 30px !important;
        }
        h3.count {
            color: #6a8dec;
            margin-left: 5px;
        }
        .filters {
            display: block;
        }
    }
    background-color: transparent;
    .all-products-container {
        // box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 15px 0px 20px;
        .display-options {
            margin-right: -2px;
            margin-top: 15px;
        }
        .pagination-wrapper {
            margin-right: 15px;
            margin-top: 20px;
        }
    }
}

.product-item {
    cursor: pointer;
    width: 100%;
    height: 175px;
    transform: $transition-base;
    box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
    padding: 10px;
    border-radius: 12px;

    &:hover {
        @include box-shadow($btn-hover-box-shadow);
        transition: transform 2s;
    }

    span.verrsion.active {
        background-color: #3b62f0;
    }

    span.version.concept {
        background-color: #f4a850;
    }

    .header {
        display: flex;
        align-items: center;
        span {
            color: white;
            border-radius: 6px;
            font-size: 12px;
            min-width: 40px;
            padding: 2px 7px;
            text-align: center;
            background-color: #3f65f1;
            margin-right: 6px;
        }

        i.flag {
            position: absolute;
            right: 10px;
            font-size: 22px;
        }
    }

    .detail {
        display: flex;
        height: 100%;
        align-items: center;

        .product-image {
            background-size: cover;
            height: auto;
            width: 100%;
            flex: 1 10px;

            img {
                width: 110px;
                height: 109px;
            }
        }

        .product-description {
            flex: 2 5px;
            align-self: center;
            margin: 0 0 0 8px;

            h5 {
                width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .row {
                p {
                    margin: 0;
                    font-size: 0.8em;
                    font-weight: bold;
                }
            }
            span {
                display: flex;
                svg {
                    margin-right: 2px;
                    height: 18px;
                }

                small {
                    font-weight: bolder;
                }
            }
        }
    }
}
