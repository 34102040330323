.analytics-markets {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .row:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        padding: 25px 200px;
        p {
            text-align: center;
        }
    }
    .market-connection {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        box-shadow: 0 0px 5px 0 rgba(57, 57, 57, 0.2);
        border-radius: 12px;
        margin: 15px;
        padding: 15px;
        span {
            display: flex;
            align-items: center;
            img {
                margin: 15px 0;
            }
        }   
    }
}