* {
  outline: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  color: $body-color;
  background: $main-body-bg;
  font-family: Euclid Circular A, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #273f52;
  letter-spacing: 0.5px;
}

html {
  position: relative;
}