.version-history {
	margin: 20px 0px;
	.expand-history {
		cursor: pointer;
		display: flex;
		justify-content: center;
		position: relative;
		margin: 1.5rem;
		margin-bottom: 2rem;
		font-size: 11pt;
		.expand-history-btn {
			position: absolute;
			top: -10px;
			padding: 6px 12px;
			border-radius: 6px;
			box-shadow: 0 2px 8px #2c3e501c;
			transition: all 0.2s ease-in-out;
			&:hover {
				background-color: #3f65f1;
				top: -9px;
				color: #fff;
			}
		}
	}
}
