.dashboard-cost-chart {
    .cost-header {
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        h4 {
            margin: 0;
        }
        .legend {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: flex-end;
            margin-left: auto;
            text-align: center;
            div {
                margin-right: 10px;
            }
            span {
                margin-right: 20px;
            }
        }
    }  
}