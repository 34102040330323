.packaging {

  .alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
    font-size: 16pt;
    a {
      cursor: pointer;
      color: #3f65f1;
    }
    * {
      margin: 15px 0px;
    }
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #273f52;
  }

  table {
    margin-top: 30px;
    font-size: 16px;

      tr.list-row {
        // Hover style on list view row 
        td {
          cursor: pointer;
          position: relative;
          left: 0;
          transition: top 0.2s ease-in-out;
        }
        &:hover {
          background-color: #e1ecf346;
          td {
            left: 2px;
            &:first-of-type {
              border-radius: 6px 0 0 6px;
            }
            &:last-of-type {
              border-radius: 0 6px 6px 0;
            }
          }
        }
      }

    thead {
      tr {
        border-bottom: none;
        th {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: #657280;
        }
      }
    }
    tr {
        border-bottom: 1px solid #cedbe9;
        padding-bottom: 18px;
        padding-top: 18px;
        td {
            vertical-align: middle;

            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #657280;

            img {
              width: 32px;
              height: 40px;
            }

            &.package-name {
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              color: #273f52;
            }


            span {

              &.cost {
                padding: 3px 12px 3px 12px;
                border-radius: 6px;
                background-color: #0ed7ac;
                // background-color: lightgrey;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.38px;
                text-align: center;
                color: #ffffff;
                text-transform: capitalize;
                white-space: nowrap;
                * {
                  display: inline-block;
                }
              }
              
              &.label {
                color: white;
                padding: 3px 12px 3px 12px;
                border-radius: 6px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.38px;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
                &.label-concept {
                  background-color: #607d8b;
                }
                &.label-retail {
                  background-color: #3f65f1;
                }
              }

              &.action {
                border: 2px solid #d4dfec;
                border-radius: 5px;
                padding: 7px 10px 7px 10px;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                color: #657280;
                white-space: nowrap;
                * {
                  display: inline-block;
                }
              }
              &.action:hover {
                background-color: #f1f2f3;
              }
            }
        }
    }
}
}


.package-modal {
  .package-header {
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    &.row {
      margin: 0 15px !important;
    }
  
    span {
      &.cost {
        padding: 3px 12px 3px 12px;
        border-radius: 6px;
        background-color: #0ed7ac;
        // background-color: lightgrey;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        text-align: center;
        color: #ffffff;
        text-transform: capitalize;
      }
  
      &.label {
        color: white;
        padding: 3px 12px 3px 12px;
        border-radius: 6px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        text-transform: capitalize;
        &.label-concept {
          background-color: #607d8b;
        }
        &.label-retail {
          background-color: #3f65f1;
        }
      }
  
    }
  }

  .img-container {
    margin-left: 30px;
    img {
      width: 96px;
      height: 121px;
    }
  }


  h3 {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #273f52;
  }

  .info {
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #657280;
      display: block;
    }
  }

  .tags {
    color: #6b7885;
    .tag {
      display: inline-block;
      margin-bottom: 5px;
      border: 2px solid #d4e0ec;
      border-radius: 6px;
      padding: 6px 12px 6px 12px;
      margin-right: 5px;
    }
  }

  .product-types {
    color: #6b7885;
    .product-type {
      display: inline-block;
      margin-bottom: 5px;
      border: 2px solid #d4e0ec;
      border-radius: 6px;
      padding: 6px 12px 6px 12px;
      margin-right: 5px;
    }
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #273f52;
  }

  .modal-close {
    cursor: pointer;
    
  }

  .modal-close:hover {
    color: #d4e0ec;
  }


}



