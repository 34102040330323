.auth-container {
	display: flex;
	width: 100%;
	min-height: 100vh;

	.image-container {
		width: 42%;
		display: flex;
		background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.6) 100%);
		flex-direction: column;
		align-items: center;

		.logo {
			margin-top: 46px;
		}

		.alpha {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
		}
	}

	.auth-container {
		width: 58%;
		display: flex;
		background: rgba(4, 25, 40, 0.05);
		padding: 0 76px;
		flex-direction: column;

		.logo-rightside {
			display: none;
		}

		.content-header {
			margin-top: 26px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			height: 35px;

			.header-text {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				color: #141928;
			}

			.header-link {
				margin-left: 18px;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
				color: #17c9dc;
			}
		}

		.content {
			display: flex;
			align-items: center;
			width: 100%;
			display: flex;
			height: 100%;
		}

		.btn-success {
			background: #17c9dc;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
			border-radius: 8px;
			min-width: 150px;
			height: 50px;
			border-color: transparent;
		}
		.btn-success:hover {
			background-color: #53ce7c;
		}

		.btn-success.disabled {
			background-color: #04192840;
		}
	}
}

.login,
.register,
.forget-password-notification,
.recovery-password {
	display: flex;
	flex-direction: column;
	width: 100%;

	.header {
		display: flex;
		align-items: center;
		flex-direction: column;

		h1 {
			font-style: normal;
			font-weight: 600;
			font-size: 24px;
			line-height: 32px;
			color: rgba(4, 25, 40, 0.8);
		}
	}

	.row {
		padding-top: 23px;
		margin: 0;
	}

	.btn-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 30px;
	}
}

.login {
	.login-action {
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		padding-top: 42px;

		a {
			color: #17c9dc;
			border-bottom: 1px solid #17c9dc;
		}
	}
	.login-form-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		.row {
			width: 330px;
		}
	}

	.btn-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 35px;
	}
}

.register-success {
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(4, 25, 40, 0.05);
	border-radius: 6px;
	align-items: center;
	padding: 0 20px;

	.register-success-icon {
		margin-top: 100px;
	}

	.register-success-header {
		margin-top: 35px;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		color: #041928;
		text-align: center;
	}

	.register-success-text {
		padding-top: 45px;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		color: #554f4f;
	}

	.register-success-btn {
		margin-top: 97px;
		margin-bottom: 118px;
	}
}

.recovery-password {
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(4, 25, 40, 0.05);
	border-radius: 6px;
	align-items: center;

	.recovery-password-icon {
		margin-top: 60px;
		margin-bottom: 45px;
	}

	.recovery-password-form {
		margin-bottom: 110px;
	}

	.recovery-form-content {
		max-width: 330px;
		margin: 0 15px;
	}

	.btn-container {
		padding-top: 60px;
	}
}

.forget-password {
	width: 100%;
	align-items: center;
	flex-direction: column;
	box-sizing: border-box;
	padding: 0px 80px;

	.forget-password-icon {
		margin-top: 60px;
	}

	.forget-password-header {
		padding-top: 33px;
		padding-bottom: 47px;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		text-align: center;
		color: #041928;
	}
	.forget-password-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 330px;
	}

	.forget-password-button {
		padding-top: 50px;
	}

	.forget-password-action {
		padding-top: 67px;
		margin-bottom: 115px;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		text-align: center;

		a {
			color: #17c9dc;
			border-bottom: 1px solid #17c9dc;
		}
	}
}

.password-instruct {
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(4, 25, 40, 0.05);
	border-radius: 6px;
	align-items: center;

	.password-instruct-icon {
		margin-top: 100px;
	}

	.password-instruct-header {
		margin-top: 35px;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		color: #041928;
		text-align: center;
	}

	.password-instruct-text {
		padding-top: 60px;
		padding-left: 50px;
		padding-right: 50px;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		color: #04192880;
		display: flex;
		flex-direction: column;
	}

	.password-instruct-text-span {
		padding-top: 20px;
	}

	.password-instruct-action {
		margin-top: 85px;
		margin-bottom: 155px;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		text-align: center;

		a {
			color: #17c9dc;
			border-bottom: 1px solid #17c9dc;
		}
	}
}

.login-verify {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-width: 466px;

	.login-verify-icon {
		margin-top: 60px;
	}

	.login-verify-header {
		padding-top: 33px;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		color: #041928;
	}
	.login-verify-text {
		padding-top: 30px;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		color: rgba(4, 25, 40, 0.5);
	}

	.login-verify-button-container {
		padding: 50px 0;
	}

	.login-verify-action {
		padding-bottom: 40px;
		a {
			color: #17c9dc;
			border-bottom: 1px solid #17c9dc;
		}
	}

	.custom-styles {
		padding-top: 40px;
		--ReactInputVerificationCode-itemWidth: 60px;
		--ReactInputVerificationCode-itemHeight: 70px;
	}

	.custom-styles .ReactInputVerificationCode__item {
		position: relative;
		color: #262626;
		font-weight: 500;
	}

	.custom-styles .ReactInputVerificationCode__item,
	.custom-styles .ReactInputVerificationCode__item.is-active {
		box-shadow: none;
		border: 1px solid #b9b9b9;
		box-sizing: border-box;
		border-radius: 10px;
		font-style: normal;
		font-weight: 600;
		font-size: 40px;
		line-height: 54px;
		text-align: center;
		color: #17c9dc;
		padding-top: 6px;
		text-decoration: none !important;
	}

	.custom-styles .ReactInputVerificationCode__item.is-active:after {
		content: "";
		display: block;
		position: absolute;
		left: 10px;
		bottom: 14px;
		width: 40px;
		right: 0;
		height: 1px;
		background-color: #17c9dc;
		transition: background-color 0.2s ease-out;
	}

	.custom-styles .ReactInputVerificationCode__item.is-active {
		border-color: #17c9dc !important;
		text-decoration: underline;
	}

	.custom-styles .ReactInputVerificationCode__item.is-filled {
		border: 1px solid #17c9dc;
	}
}

@media only screen and (max-width: 835px) {
	.auth-container {
		.image-container {
			display: none;
		}

		.auth-container {
			width: 100%;
			.logo-rightside {
				display: block;
			}
		}
	}
}
