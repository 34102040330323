@import 'product-overview';

.dash-v2 {
    .card {
        border-radius: 6px;
        width: 100%;
        margin-bottom: 15px;
    }
    .row {
        margin: 0;
        &:first-of-type{
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-left: 0;
            @media only screen and (max-width: 1425px) {
                justify-content: flex-start;
            }
        }
    }
    .col-side, .col-middle {
        padding: 0;

    }
    .col-middle{
        max-width: 655px;
        @media only screen and (min-width: 1920px) and (max-width: 1920px){
            max-width: 1100px;
        }
        flex-grow: 1;
        margin: 0 15px;
    }
    .col-side {
        min-width: 275px;
        max-width: 275px;
        // max-height: 800px;
        min-height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .row {
            justify-self: flex-start;
        }
        .card {
            height: 250px;
        }
        .product-overview-card {
            height: 100%;
        }
        .recent-card {
            height: 550px;
        }
    }
}