@keyframes progress-bar-stripes {
	from {
		background-position: $progress-height 0;
	}
	to {
		background-position: 0 0;
	}
}

.progress {
	display: flex;
	height: $progress-height;
	overflow: hidden; // force rounded corners by cropping it
	font-size: $progress-font-size;
	background-color: $progress-bg;
	@include border-radius($progress-border-radius);
	@include box-shadow($progress-box-shadow);
}

.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: $progress-bar-color;
	text-align: center;
	white-space: nowrap;
	background-color: $progress-bar-bg;
	// background-image: linear-gradient(to right, #e1ecf3, $progress-bar-color);
	@include transition($progress-bar-transition);
}

.progress-bar-striped {
	@include gradient-striped();
	background-size: $progress-height $progress-height;
}

.progress-bar-animated {
	animation: progress-bar-stripes $progress-bar-animation-timing;
}

/* 
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
	position: relative;
	// z-index: 100;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	left: 50%;
	bottom: calc(100% + 5px); /* 5px is the size of the arrow */
	pointer-events: none;
	transition: 0.2s;
	will-change: transform;
	z-index: 100;
}

/* Tool tip for progress bar */
/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
	content: attr(data-tooltip);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px 12px;
	// max-height: 25px;
	// max-width: 25px;
	border-radius: 6px;
	font-size: 14px;
	background-color: rgba(59, 72, 80, 0.9);
	background-image: linear-gradient(
		30deg,
		rgba(59, 72, 80, 0.44),
		rgba(59, 68, 75, 0.44),
		rgba(60, 82, 88, 0.44)
	);
	box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
	color: #fff;
	text-align: center;
	white-space: pre-wrap;
	transform: translate(-50%, -5px) scale(0.5);
	z-index: 100;
}

/* Tooltip arrow */
[data-tooltip]:after {
	content: "";
	border-style: solid;
	border-width: 6px 5px 0px 5px; /* CSS triangle */
	margin-top: -5px !important;

	border-radius: 0px;
	border-color: rgba(59, 72, 80, 0.9) transparent transparent transparent;
	transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
	transform-origin: top; /* Orientation setting for the
                              slide-down effect */
	transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
	visibility: visible;
	opacity: 1 !important;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
	transition-delay: 0.05s;
	transform: translate(-50%, -5px) scale(1);
}
/* 
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
	transition-delay: 0.3s; /* Starting after the grow effect */
	transition-duration: 0.3s;
	transform: translateX(-50%) scaleY(1);
}
/*
  That's it.
*/
