%items {
    padding: 8px 13px;
    margin-right: 6px;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    border: 2px solid #cedbe9;
    justify-content: center;
    align-items: center;
}

.porfolio-preference {
    padding: 20px;
    h5 {
        font-weight: 8;
    }
    overflow: visible;

    span.add {
        display: none;
        font-size: 1.5em;
        position: static;
        align-items: baseline;
        right: 30px;
        top: 25%;
        p {
            margin-right: 5px;
        }
    }
}

.portfolio-preference-list {
    align-items: center;
    display: flex;
    overflow-x: auto;
    flex-wrap: wrap;
    .portfolio-preference-item {
        @extend %items;
        p {
            margin-bottom: 0;
            font-weight: 600;
        }
        i {
            color: #f4a850;
            margin-right: 7px;
        }

        @media only screen and (max-width: 720px) {
            min-width: 40%;
        }
    }
}
