
.upgrade-prompt {
    margin-top: 0!important;
    align-self: center;
    justify-self: center;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
        color: #2c3e50;
        margin-left: 0 !important;
    }
    span {
        background-color: #fff;
        border: 1px solid #cedbe9;
        border-radius: 12px;
        font-size: 14px !important;
        color: #2c3e50;
        display: flex;
        align-items: center;
        position: relative;
        top: 0;
        transition: top 0.2s ease-in-out;
        width: max-content;
        padding: 6px 12px;
        svg {
            stroke: #f4a850;
            color: #f4a850!important;
            margin-right: 10px !important;
        }
        cursor:pointer;
        &:hover {
            @extend .custom-shadow;
            top: -1px;
            background-color: #cedbe9;
        }
    }
}