.invoices {
	.row:first-of-type {
		display: flex;
		flex-direction: column;
		h4 {
			align-items: center;
			.invoice-status {
				margin: 15px;
				font-size: 12pt;
			}
			.invoice-created {
				float: right;
				span {
					margin-left: 15px;
					font-size: 12pt;
				}
			}
		}
	}

	.invoice-total {
		float: right;
		margin-left: auto;
		span {
			margin-top: 10px;
			&:first-of-type {
				float: left;
				margin-right: 15px;
				color: #8fa1b3;
			}
			&:last-of-type {
				float: right;
			}
		}
	}

	@extend .clean-table;

	.nav-back {
		// font-size: 14pt;
		display: flex;
		align-items: center;
		background-color: #3f65f1;
		color: #fff;
		padding: 6px 12px;
		border-radius: 6px;
		width: fit-content;
		position: relative;
		left: 0;
		transition: left 0.2s ease-in-out;
		cursor: pointer;
		svg {
			position: relative;
			left: -2px;
			transition: left 0.2s ease-in-out;
		}
		&:hover {
			left: -2px;
			svg {
				left: -4px;
			}
		}
	}

	.invoice-details {
		display: flex;
		flex-direction: column;
		.details {
			display: flex;
			flex-direction: column;
		}
		.btn {
			width: fit-content;
		}
	}
}
