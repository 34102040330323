.range-inputs {
	display: flex;
	align-items: center;
	margin-left: 30px;
	margin-bottom: 1.5rem;
	.form-group {
		background: #f9f9fa;
		border: 1px solid #ebebec;
		width: auto;
		height: auto;
		box-shadow: 0px 0px 20px rgba(4, 25, 40, 0.1);
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0;
		padding: 2px 4px 0px 2px;
		&.last-item {
			margin-left: 5px;
		}
		label {
			color: #000;
			font-size: 16px;
			padding: 0;
			margin: 0;
		}
		input[type="number"] {
			max-width: 34px;
			margin-left: 4px;
			border: 0;
			outline: 0;
			&::placeholder {
				font-size: 16px;
				color: rgb(102, 102, 102);
			}
			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				margin: 0;
			}
		}
	}
	.range-btn {
		margin-left: 5px;
		background: #395efa;
		box-shadow: 0px 0px 20px rgba(4, 25, 40, 0.1);
		border-radius: 4px;
		color: #f9f9fa;
		font-size: 16px;
		padding: 1px 8px 0px;
		&.btn-secondary:hover,
		&.btn-secondary:focus,
		&.btn-secondary:active {
			box-shadow: none;
			transform: none;
			color: #f9f9fa !important;
			background: #395efa !important;
		}
	}
}
