td.ghost {
	// border: none !important;
	height: 72px;
	width: max-content;
	.content-ghost {
		@extend .loading-gradient;
		height: 15px;
		width: 100%;
	}
}
