.admin-recommendations {
	.status-label {
		position: relative;
		transition: all 0.1s ease-in-out;
		border: 1px solid;
		border-radius: 8px;
		&.requested {
			border-color: #f5a84f;
			color: #f4a850;
		}
		&.recommended {
			border-color: #0ed7ac;
			color: #0ed7ac;
		}
		&:hover {
			cursor: pointer;
			color: #fff;
			&.requested {
				background-color: #f5a84f;
			}
			&.recommended {
				background-color: #0ed7ac;
			}
		}
	}
}
