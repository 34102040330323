.active-product {
    min-height: 180px;
    .title {
        @extend .mb-3;
        display: flex;
        align-items: baseline;

        h5 {
            margin-right: 5px;
        }
        .retail-button {
            background-color: #3b62f0;
            margin-right: 5px;
        }

        .count {
            color: #779afc;
        }
    }
}
