.dashboard-overview {
    .card {
        @extend .border-radius-12;
        height: 190px;
    }
    .starred {
        box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
        background-image: linear-gradient(to bottom, #fad388, $bg-yellow);
        width: 134px;
        justify-content: center;
    }
    .row {
        margin: 0;
    }
    .card-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px;
    }
    .card-title {
        display: flex;
        align-items: center;
    }
    .fa-star {
        font-size: 22px;
    }
    .card-subtitle {
        font-size: 1rem;
        font-weight: normal;
        color: $battleship-grey;
        a {
            font-size: 0.6875rem;
            color: #fff;

            &:hover {
                color: whitesmoke;
            }

            &::after {
                content: ">>";
                margin-left: 1px;
            }
        }
    }

    .active-product {
        display: flex;
        width: 339px;
        box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
        background-image: linear-gradient(to bottom, #739ef9, $bg-blue);

        .chart {
            flex: 1 1 auto;
        }

        .card-title,
        .card-subtitle {
            color: #fff;
        }
    }

    .concept-product {
        display: flex;
        width: 339px;
        box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
        background-image: linear-gradient(to bottom, #89e4d6, $bg-green);

        .chart {
            flex: 1 1 auto;
        }

        .card-title,
        .card-subtitle {
            color: #fff;
        }
    }
    .co-info {
        box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
        border: solid 2px $powder-blue;
        background-color: #ffffff;
        // width: 339px;
        
        .card-subtitle {
            // border-bottom: solid 2px var(--powder-blue);
            margin: 9px 0;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #273f52;

            .details {
                font-size: 14px;
                color: #657280;
                letter-spacing: 0.5px;
            }
        }
        .title {
            display: flex;
            justify-content: space-between;   

            .card-title{
                width: 76px;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                color: #273f52;
                margin-bottom: 0;
            }

          
            .logo {
                display: inline-flex;
                align-items: center;
                i {
                    font-size: 15px;
                    margin-right: 20px;

                    &.fa-circle{
                        color: var(--bg-green-light);
                    }
                }

                img {
                    // width: 70px;
                    // height: 25px;
                }
            }
        }
    }
    .data-tooltip {
        border-radius: 4px;
        padding: 7px 10px;
        font-size: 12px;
        background: #3a3a48;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        color: #fff;;
        text-align: left;
        white-space: nowrap;
        margin: 5px;
    }
}

.title-container {
    display: flex;
    align-items: center;
    margin-top: 27px;
}

.inactive {
    opacity: 0.5;
}

// NEW DASH OVERVIEW STYLES
.new-dashboard-overview {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .card {
        @extend .border-radius-12;
        max-height: 425px;
        // height: 190px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.185);
        h4 {
            font-weight: bold;
        }
        &.dash-chart {
            padding-top: 15px;
            width: 425px;
            height: 100%;
            overflow-x: hidden;
            .card-body {
                padding-top: 0px;
            }
            .chart-details {
                align-self: flex-start;
                position: absolute;
                color: #2c3e50;
                div {
                    margin-top: 15px !important;
                    font-size: 11pt;
                    .detail {
                        display: flex;
                        align-items: center;
                        padding-top: 10px;
                        h3 {
                            margin: 0 5px;
                        }
                        .product-btn {
                            height: 30px;
                            width: 30px;
                            padding: 6px;
                            border-radius: 6px;
                            display: flex;
                            align-items: center;
                            position: relative;
                            top: 0;
                            transition: top 0.2s ease-in-out;
                            cursor: pointer;
                            &:hover {
                                top: -1px;
                            }
                        }
                    }
                }
            }
            canvas {
                max-height: 200px !important;
                margin-left: 80px;
                z-index: 10;
            }
        }
        &.notifications {
            height: 100%;
            background-image: linear-gradient(to bottom, #e1ecf3, #cedbe9);
            // background-image: linear-gradient(to bottom, #739ef9, $bg-blue);
            color: #2c3e50;
            min-height: 220px;
            .card-body {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;
                padding: 0px 18px;
                div {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    h1 {
                        margin-right: 20px;
                    }
                    a {
                        margin-top: 15px;
                        margin-left: auto;
                        color: #2c3e50;
                        font-size: 14px;
                    }
                }
                .account-completion {
                    display: flex;
                    flex-direction: column;
                    .progress-bar {
                        background-color: #0ed7ac !important;
                        height: 100%;
                    }
                    p {
                        font-size: 14px;
                        margin: 0 auto 0 0;
                    }
                }

            }
        }
        &.product-changes {
            display: flex;
            height: 220px !important;
            max-height: none !important;
            overflow-y: hidden;
            color: #2c3e50;
            margin-right: 0;
            small {
                color: #8fa1b3;
            }
            .card-body {
                flex: unset;
                margin-top: 0 !important;
                padding: 0px 18px;
            }
            .chart {
                flex: 1 1 auto;
                stroke: #2c3e50 !important;
            }
            .chart-header {
                margin-top: 15px;
                display: flex;
                flex-direction: row;
                h4 {
                    margin-bottom: 0px;
                }
                select {
                    margin-left: auto;
                }
            }
    
            .card-title,
            .card-subtitle {
                color: #2c3e50;
            }
        }
        &.recent-activity {
            width: 705px;
            .card-body {
                h4 {
                    margin-right: auto;
                }
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .recent-products {
                    display: flex;
                    flex-direction: row;
                    margin-top: 20px;
                    div {
                        margin-right: 30px;
                        width: 280px;
                    }
                    h5 {
                        border-radius: 6px;
                        padding: 8px;
                        color: #fff;
                        display: inline-block;
                        cursor: pointer;
                        position: relative;
                        top: 0;
                        transition: top 0.1s ease-in-out;
                        &:hover {
                            top: -2px;
                            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.185);
                        }
                        &.retail {
                            background-color: #3f65f1;
                        }
                        &.concept {
                            background-color: #0ed7ac;
                        }
                    }
                }
                .product-card-small {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: auto;
                    margin-left: 0;
                    padding: 6px;
                    cursor: pointer;
                    position: relative;
                    top: 0;
                    transition: top 0.2s ease-in-out;
                    &:hover {
                        background-color: #e1ecf349;
                        top: -3px;
                        border-radius: 6px;
                    }
                    span {
                        max-width: 200px;
                        overflow-x: scroll;
                        white-space: nowrap;
                    }
                    .product-card-small-img { 
                        max-height: 24px;
                        max-width: 24px;
                        min-height: 24px;
                        min-width: 24px;
                        overflow: hidden;
                        margin-right: 15px;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
    .starred {
        background-image: linear-gradient(to bottom, #fad388, $bg-yellow);
        width: 134px;
        justify-content: center;
    }
    .row {
        margin: 0;
    }
    .col {
        padding: 0px 0px 15px 0px;
    }
    .card-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px;
    }
    .card-title {
        display: flex;
        align-items: center;
    }
    .fa-star {
        font-size: 22px;
    }
    .card-subtitle {
        font-size: 1rem;
        font-weight: normal;
        color: $battleship-grey;
        a {
            font-size: 0.6875rem;
            color: #fff;

            &:hover {
                color: whitesmoke;
            }

            &::after {
                content: ">>";
                margin-left: 1px;
            }
        }
    }
    .data-tooltip {
        border-radius: 4px;
        padding: 7px 10px;
        font-size: 12px;
        background: #3a3a48;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        color: #fff;;
        text-align: left;
        white-space: nowrap;
        margin: 5px;
    }
}

.title-container {
    display: flex;
    align-items: center;
    margin-top: 27px;
}

.inactive {
    opacity: 0.5;
}
