.custom-radio-btn {
	input[type="radio"] {
		cursor: pointer;
		position: relative;
		appearance: none;
		width: 13.5px;
		height: 13.5px;
		background-color: #f9f9fa;
		&::after,
		&:checked::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border-radius: 2px;
		}
		&::after {
			border: 1px solid #041928;
			box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.05);
		}
		&:checked::after {
			background: #17c9dc;
			border: 1px solid #17c9dc;
			box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.15);
		}
	}
	label {
		cursor: pointer;
		font-weight: 500;
		font-size: 16px;
		color: #000;
		margin-left: 10px;
	}
}
