.package-single {
    .row {
      margin: 0;
    }
  
    img.package-single-image {
      width: 100%;
      height: auto;
      border-radius: 6px;
      border: 2px dashed #e1ecf3;
      width: 200px;
      height: 200px;
    }
  
    .ingredient-single__name {
      button {
        height: 40px;
      }
    }

    .description {
        max-width: 400px;
        float: left;
        margin-right: auto;
        margin-left: 0;
        .materials, .tags {
            display: flex;
            flex-wrap: wrap;
            .material-tag, .tag {
                display: inline-block;
                border: 2px solid #d0dcea;
                padding: .375rem .75rem;
                font-size: .875rem;
                line-height: 1.5;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                font-weight: 600;
                border-radius: 6px;
                margin-right: 6px;
                margin-bottom: 6px;
            }
        }
    }

    .scores {
        @extend .custom-shadow;
        border-radius: 6px;
        width: 100%;
        height: 100px;
    }
  }
  