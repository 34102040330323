.personas-integrations {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 15px;
	h4 {
		margin: 0 20px 0 0;
	}
	span {
		border-radius: 12px;
		background-color: #89cff05d;
		padding: 3px 12px;
		animation: blinker 2s linear infinite;
	}

	@keyframes blinker {
		50% {
			opacity: 55%;
		}
	}
}

.personas {
	display: grid;
	grid-template-columns: 100%;
	grid-column-gap: 15px;
	row-gap: 15px;
	// max-width: 1380px;
	// max-width: 95%;
	button {
		position: fixed;
		bottom: 50px;
		right: 50px;
		color: #4469f1;
		font-weight: lighter;
		background-color: #e1ecf3;
	}
	@media screen and (min-width: 992px) {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 18px;
	}
	@media screen and (min-width: 1200px) {
		grid-template-columns: 23% 23% 23% 23%;
		grid-column-gap: 18px;
	}
}

.persona {
	&.mini {
		@extend .p-3;
		position: relative;
		background-color: white;
		display: flex;
		width: 280px;
		align-items: center;
		flex-direction: row;
		.selected-tag {
			position: absolute;
			background-color: #3f65f1;
			padding: 6px;
			border-radius: 6px;
			top: -5px;
			left: -5px;
			display: flex;
			align-items: center;
			justify-content: center;
			i {
				margin-right: 0;
				font-size: 12px;
			}
		}
		& > div {
			@extend .flex-row;
			align-items: center;
		}
		img {
			position: static;
			border: none;
			transform: translate(0, 0);
			width: 49px;
			height: 49px;
			display: block;
			margin-right: 1.5rem;
		}
		.details {
			margin: 0 10px;
			strong {
				font-size: 1.2em;
			}
		}

		.product-info {
			padding: 0;
			box-shadow: none;

			.name {
				margin: 0;
			}
			.stats {
				margin-top: 5px !important;
			}
		}
	}

	.top-gradient {
		height: 75px;
		background-image: linear-gradient(to bottom, #89e4d6, $bg-green);
		border-radius: 12px 12px 0 0;
		backdrop-filter: blur(10px);
		position: relative;
	}
	@extend .border-radius-12;
	@extend .custom-shadow;
	display: flex;
	flex-direction: column;
	max-width: 430px;
	position: relative;

	.persona-suitability {
		background: white;
		padding: 15px 24px 24px 24px;
		border-radius: 0 0 12px 12px;
	}

	.scrollbar-container {
		height: 60%;
	}

	img {
		z-index: 1;
		border: 3px solid white;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(40%, -60%);
		border-radius: 100%;
		width: 55px;
		height: 55px;
	}
	i {
		margin-right: 20px;
		color: white;
		font-size: 17px;
	}

	.product-info {
		background-color: white;
		padding: 30px 24px;
		padding-bottom: 0;
		.stats {
			display: flex;
			flex-flow: row wrap;
			font-size: 1em;
			justify-content: space-between;
			p {
				margin-bottom: 0;
				&.type {
					color: $battleship-grey;
				}

				&.value {
					font-weight: bold;
					&.code {
						color: #f4a850;
					}
				}
			}
		}
	}
}
