.ingredient-single {
	.row {
		margin: 0;
	}

	.grocer-select {
		width: 125px !important;
		height: 40px;
		border-radius: 6px;
		height: 40px;
		border: solid 1px var(--powder-blue);
		z-index: 15;
		.grocer-dropdown {
			// border-top: 0;
			// border-left: 5px solid transparent;
			// border-right: 5px solid transparent;
			// border-bottom: 5px solid #4d4d4d;
			border-top: 5px solid #4d4d4d;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 0;
		}
		.grocer-select__control {
			border: none !important;
		}
		.grocer-select__indicator-separator {
			width: 0 !important;
		}
	}
	div.ingredient-single__image__container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: 300px;
		width: 300px;
		img.ingredient-single__image {
			width: auto;
			height: 100%;
			border-radius: 6px;
		}
	}

	.ingredient-single__name {
		button {
			height: 40px;
		}
	}

	.flag-select {
		height: 40px;
		border-radius: 6px;
		height: 40px;
		border: solid 1px var(--powder-blue);
	}

	.flag-select__option__icon {
		top: 0;
	}

	.flag-select__option__label {
		color: #657280;
	}

	.flag-select__options {
		width: 97px;
	}

	.flag-select__option {
		margin: 4px 0px;
	}

	.flag-select__btn {
		margin-top: 2px;
	}

	.table-responsive {
		overflow-x: visible !important; // Unset Reactstrap attribute to allow for tooltip
	}
	.ingredient-info-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 2px solid #d0dcea;
		overflow-y: visible;
		.regulation-links {
			.regulation-link-header {
				font-size: 12pt;
				border-bottom: 1px solid #e1ecf3;
				padding-bottom: 3px;
				margin-top: -9px;
			}
			p {
				font-weight: bold;
			}
			margin-top: 8px;
			position: absolute;
			z-index: 1000;
			width: 300px;
			height: 300px;
			border: 2px solid #e1ecf3;
			border-radius: 6px;
			background-color: #fff;
			box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
			padding: 12px;
			overflow-y: scroll;
		}
	}

	.table th,
	.table td {
		margin: 0px;
		padding: 10px 0px 10px 0px;
	}

	.ingredient-info-pill {
		display: inline-block;
		border: 2px solid #d0dcea;
		padding: 0.375rem 0.75rem;
		font-size: 0.875rem;
		line-height: 1.5;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		font-weight: 600;
		border-radius: 6px;
		margin-right: 6px;

		&:hover,
		&:active {
			background-color: #e1ecf3;
		}
		span {
			color: #697e99;
		}
	}
}
