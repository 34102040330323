.portfolio-score {
	&.card,
	.card-body {
		box-shadow: none;
		display: flex;
		flex-direction: column;
		padding-bottom: 0;
		h4 {
			align-self: center;
		}
		.score {
			align-self: center;
			h1 {
				margin-bottom: 0;
				font-size: 72px;
			}
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.score-graph {
			.graph {
				height: 115px;
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				// justify-content: space-between;
				justify-content: center;
				.bar {
					width: 20px;
					height: 0px;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					transition: height 0.15s linear;
					svg,
					img {
						display: none;
					}
					&.load {
						height: 100%;
						div {
							border-radius: 6px;
						}
						svg,
						img {
							display: inline;
							width: 20px !important;
							max-height: 20px !important;
							margin-bottom: 10px;
							align-self: center;
						}
					}
				}
			}
		}
	}
}
