ul.first {
    padding-left: 0;
}
.category {
    padding: 7px;
    border-radius: 8px;
    border: 2px solid rgb(206, 219, 233);

    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    cursor: default;
    &.selected { 
        background-color: #3f65f1;
        border-color: #3f65f1;
        color: #fff; 
    }
}