.integration-grid {
	@extend .clean-table;

	display: grid;
	padding: 0;
	max-width: 1120px;
	.card {
		&-body {
			.card-title {
				font-size: 18px;
			}
			.card-text {
				color: $battleship-grey;
			}
			.btn {
				font-weight: 600;
			}
		}
	}
	& > div {
		// height: 350px;
		height: auto;
	}
	@media only screen and (min-width: 768px) {
		grid-template-columns: minmax($inntegration-card-width, 1fr) minmax(
				$inntegration-card-width,
				1fr
			);
		grid-gap: 24px;
	}
	@media only screen and (min-width: 992px) {
		grid-template-columns:
			minmax($inntegration-card-width, 1fr) minmax($inntegration-card-width, 1fr)
			minmax($inntegration-card-width, 1fr);
		grid-gap: 24px;
	}
	@media only screen and (min-width: 1200px) {
		grid-template-columns:
			minmax($inntegration-card-width, 1fr) minmax($inntegration-card-width, 1fr) minmax(
				$inntegration-card-width,
				1fr
			)
			minmax($inntegration-card-width, 1fr);
		grid-gap: 24px;
	}
}

.integration {
	.row {
		display: flex;
		flex-direction: row;

		.integration-connection {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 200px;
			// width: 250px;
			width: 200px;
			box-shadow: 0 0px 5px 0 rgba(57, 57, 57, 0.2);
			border-radius: 12px;
			margin: 15px;
			padding-bottom: 15px;
			button {
				margin-top: auto;
				margin-bottom: 0;
			}
			img {
				margin-top: auto;
				// margin-bottom: 15px;
				justify-self: center;
			}
		}
	}
}
