.account {
	.container {
		padding: 20px;
	}
	.product-pref-container {
		display: flex;
		flex-direction: row;
		.selected-product-pref {
			display: flex;
			flex-direction: column;
			margin-right: 0;
			margin-left: auto;
			width: 50%;
			justify-content: flex-end;
			height: max-content;
			label {
				width: 100%;
			}
			.selected-pref-section {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				padding-bottom: 15px;
				padding-top: 11px;
				&:first-of-type {
					padding-top: unset;
				}
				border-bottom: 1px dashed rgb(206, 219, 233);
			}
			.selected-pref {
				position: relative;
				height: max-content;
				.remove-pref {
					border-radius: 50%;
					background-color: #fff;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 20px;
					height: 20px;
					position: absolute;
					top: -5px;
					left: -2px;
					border: 1px solid #e1ecf3;
					svg {
						color: #e85a73;
					}
				}
				.category {
					color: #fff;
					background-color: #3f65f1;
					border-color: #3f65f1;
					margin: 0 5px 5px;
				}
			}
		}
	}
}

.preferences {
	hr {
		border-top: dotted 1px grey;
	}

	.preference {
		.arrow {
			position: absolute;
			top: 20px;
			right: 30px;
		}
	}
	.preference-list {
		padding: 10px 20px;
		display: flex;
		flex-flow: row wrap;
		button {
			background-color: #eaf1f6;
			color: var;
			border-color: #eaf1f6;
		}
		.preference-item {
			&.active {
				svg,
				p {
					color: white;
				}
				background-color: var(--bg-blue);
			}
			cursor: pointer;
			border: 1px solid #cedbe9;
			color: grey;
			margin: {
				top: 10px;
				right: 10px;
			}
			background-color: white;
			border-radius: 8px;
			min-width: 128px;
			height: 40px;
			padding: 0 13px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 1.12em;
			i {
				margin-right: 5px;
			}
			p {
				margin-bottom: 0;
				font-weight: bold;
			}
			&.product {
				svg {
					color: #3f65f1;
				}
				&.active {
					svg,
					p {
						color: white;
					}
					background-color: var(--bg-blue);
				}
			}

			&.portfolio {
				svg {
					color: #f4a850;
				}
				&.active {
					svg,
					p {
						color: white;
					}
					background-color: var(--bg-yellow);
				}
			}

			&.company,
			&.category {
				svg {
					color: #607d8b;
				}
				&.active {
					svg,
					p {
						color: white;
					}
					background-color: var(--bg-slate);
				}
			}

			// &.category {
			//     svg {
			//         color: #f4a850;
			//     }

			//     &.active {
			//         svg,
			//         p {
			//             color: white;
			//         }
			//         background-color: var(--bg-yellow);
			//     }

			// }
		}
	}
}
