.notification {
    padding: 5px;
    display: flex;
    min-height: 60px;
    
    color: white;
    i {
        font-size: 1.5em;
        margin-left: 20px;
        align-self: center;
    }

    .details {
        margin-left: 20px;
        align-self: center;
        p {
            letter-spacing: 0.8pt;
            margin-bottom: 0;
            &.title {
                font-weight: bold;
            }
        }
    }

    &.error {
        background-color: #e85a73;
    }

    &.warning {
        background-color: #3f65f1;
        .description {
            font-weight: bold;
        }
    }

    &.product {
        background-color: #3f65f1;
    }

    &.ingredient {
        background-color: #f4a850;
    }

    &.info {
        background-color: #51c5ac;
    }
}

.noty_theme__sunset.noty_bar {
    border-radius: 5px !important;
    box-shadow: 9px 5px 11px 12px rgba(0, 0, 0, 0.08);
    margin: 18px 4px !important;
    width: 410px;
}



#noty_layout__topRight {
    top: 80px;
    right: 100px !important;
    width: 325px;
    z-index: 200 !important;
}