.card.notifications {
    height: 100%;
    background-image: linear-gradient(to bottom, #e1ecf3, #cedbe9);
    // background-image: linear-gradient(to bottom, #739ef9, $bg-blue);
    color: #2c3e50;
    min-height: 220px;
    margin-bottom: 0;
    &.mini {
        min-height: unset;
        height: 200px;
    }
    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px 18px;
        div {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 15px;
            h1 {
                margin-right: 20px;
            }
            a {
                margin-top: 15px;
                margin-left: auto;
                color: #2c3e50;
                font-size: 14px;
            }
        }
        .account-completion {
            display: flex;
            flex-direction: column;
            justify-self: flex-end;
            margin-top: auto;
            .progress-bar {
                background-color: #0ed7ac !important;
                height: 100%;
                margin-top: 0;
            }
            p {
                font-size: 14px;
                margin: 0 auto 0 0;
            }
        }

    }
}