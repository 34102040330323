.subscribe {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100vh;
	.subscription-options {
		// width: 55%;
		align-self: center;
		margin-top: 75px;
		background-color: transparent;
		box-shadow: none;

		.card-body {
			display: flex;
			flex-direction: column;
			h3 {
				align-self: center;
				background-color: #fff;
				text-align: center;
				padding: 12px;
				border-radius: 6px;
				box-shadow: 0 5px 10px #2c3e5083;
			}
			.row {
				justify-content: space-evenly;
				align-items: center;
				.option {
					background-color: #fff;
					@extend .custom-shadow;
					position: relative;
					display: flex;
					flex-direction: column;
					margin: 15px;
					padding: 12px;
					border-radius: 12px;
					width: 200px;
					max-width: 250px;
					height: 375px;
					align-items: center;
					justify-content: flex-start;
					ul:first-of-type {
						list-style: none;
						line-height: 30px;
						padding-left: 20px;
						margin-bottom: 34px;
						margin-top: 35px;
						li {
							svg {
								margin-right: 10px;
								color: #0ed7ac;
							}
						}
						ul {
							padding-left: 50px;
							list-style-type: disc;
							margin: 0;
						}
					}
					.info {
						display: flex;
						align-items: center;
						justify-content: center;
						justify-self: center;
						padding: 6px 12px;
						width: fit-content;
						font-size: 10pt;
						border-radius: 12px;
						cursor: pointer;
						svg {
							margin-right: 10px;
						}
						position: relative;
						top: 0;
						transition: all 0.2s ease-in-out;
						&:hover {
							top: -2px;
							background-color: #e1ecf3;
							padding: 6px 12px;
						}
					}
					p {
						text-align: center;
						font-size: 12pt;
						margin-top: 15px;
					}
					h4 {
						margin-top: 40px;
					}
					img {
						height: 100px;
						width: 100px;
						position: absolute;
						top: -50px;
					}
					a {
						bottom: 0;
						margin-bottom: 15px;
						margin-top: auto;
						align-self: center;
					}
					.btn {
						bottom: 0;
						margin-bottom: 15px;
						margin-top: auto;
						align-self: center;
					}
					hr {
						max-width: 90%;
					}
				}
			}
		}

		.notice {
			margin-top: 20px;
			@extend .custom-shadow;
			border-radius: 12px;
			width: 50%;
			align-self: center;
			background-color: #fff;
			padding: 12px;
			text-align: center;
			a {
				color: #3f65f1;
				cursor: pointer;
			}
		}
	}

	.log-out {
		background-color: #cedbe9;
		padding: 6px 12px;
		border-radius: 6px;
		opacity: 1;
		position: absolute;
		right: 0;
		top: 0;
		margin: 15px 15px 0 0;
		transition: all 0.2s ease-in-out;
		display: flex;
		align-items: center;
		svg {
			margin-left: 10px;
		}
		cursor: pointer;
		&:hover {
			color: #fff;
			background-color: #3f65f1;
			@extend .custom-shadow;
			top: 1px;
		}
	}
}
