.modal {
	.modal-dialog.version-comparison-modal {
		max-width: unset;
		.modal-content {
			background-color: unset;
			border: none;
			box-shadow: none;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	}
}

.card.compare-card {
	min-height: 400px;
	min-width: 350px;
	border-radius: 8px;
	margin: 0 20px;
	color: #2c3e50;
	&.no-comparison {
		max-width: 350px;
		min-height: unset;
		height: max-content;
		p {
			text-align: center;
			span {
				color: #3f65f1;
			}
		}
	}
	.container {
		padding: 0;
		&.float-bottom {
			margin-top: auto;
			margin-bottom: 0;
		}
	}
	.card-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: unset;
	}
	.version-select-container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		.version-select-btn {
			border: 1px solid #3f65f1;
			color: #3f65f1;
			position: relative;
			transition: all 0.2s ease-in-out;
			cursor: default;
			&.active,
			&:hover {
				background-color: #3f65f1;
				border-color: #3f65f1;
				color: #fff;
				&:hover {
					top: -1px;
				}
			}

			padding: 4px 12px;
			border-radius: 6px;
			h5 {
				margin: 0;
				margin-right: 5px;
			}
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.versions {
			position: absolute;
			top: 32px;
			border: 1px solid #cedbe9;
			background-color: #fff;
			border-radius: 6px;
			width: 150px;
			z-index: 15;
			.version-option {
				padding: 6px 12px;
				border-top: 1px dashed #e1ecf3;
				border-bottom: 1px dashed #e1ecf3;
				background-color: #fff;
				&:hover,
				&.selected {
					background-color: #e1ecf3;
				}
				&:last-of-type {
					border-bottom: unset;
					border-bottom-right-radius: 6px;
					border-bottom-left-radius: 6px;
				}
				&:first-of-type {
					border-top: unset;
					border-top-right-radius: 6px;
					border-top-left-radius: 6px;
				}
			}
		}
	}
	.card-body {
		display: flex;
		flex-direction: column;
	}
	.row.header {
		margin-bottom: 10px;
		div {
			&:first-of-type {
				margin-right: auto;
				margin-left: 0;
			}
		}
	}
	.divider {
		border-bottom: 2px dashed #e1ecf3;
		width: 100%;
		height: 1px;
		border-top: none;
	}
	.ingredient-comparison {
		.row.header {
			@extend .row.header;
			div {
				span {
					&:first-of-type {
						color: #2c3e50;
					}
					&:last-of-type {
						margin-left: 10px;
						color: #3f65f1;
					}
				}
			}
		}
		ul.ingredients {
			list-style: none;
			padding: 0;
			width: 100%;
			li.ingredient-item {
				box-shadow: unset;
				border: 1px solid #e1ecf3;
				margin-bottom: 5px;
				border-radius: 6px;
				padding: 6px 12px;
				width: 100%;
				display: flex;
				align-items: center;
				&.uniq {
					border-color: #3f65f1;
				}
				span.ingredient-name {
					display: inline-block;
					width: 66% !important;
					max-width: 66%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				div.ingredient-percent-diff {
					margin-left: auto;
					margin-right: 0;
					color: #0ed7ac;
					display: flex;
					flex-direction: row;
					span {
						display: inline-block;
					}
					svg {
						position: relative;
						top: unset;
						right: unset;
						margin-right: 5px;
					}
					&.decrease {
						color: #f4a850;
					}
				}
				img {
					height: 20px;
					width: 20px;
					border-radius: 4px;
					margin-right: 15px;
				}
			}
		}
	}
	.cost-comparison {
		.row.header {
			@extend .row.header;
			div:last-of-type {
				span {
					&:first-of-type {
						color: #2c3e50;
					}
					&:last-of-type {
						margin-left: 10px;
						color: #2c3e50;
					}
				}
			}
		}
		.row.cost-card {
			@extend .custom-shadow;
			display: flex;
			flex-direction: column;
			padding: 12px;
			border-radius: 6px;
			.cost-row {
				display: flex;
				flex-direction: row;
				svg {
					margin-right: 10px;
				}
				&:first-of-type {
					margin-bottom: 10px;
				}
				span:last-of-type {
					justify-self: flex-end;
					margin-right: 0;
					margin-left: auto;
				}
			}
		}
	}
	.product-portfolio-scores {
		margin-top: 10px;
		justify-content: space-evenly;
		.score-section {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 5px;
			img,
			svg {
				height: 18px;
				width: 18px;
				margin-right: 10px;
			}
			width: 45%;
			margin-right: 10px;
			div div {
				//Tweaking progress gradient styles
				margin-top: 0 !important;
			}
		}
	}
	.metrics {
		svg {
			margin-right: 10px;
			height: 18px;
			width: 18px;
		}
	}
}
