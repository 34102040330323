.help-tip {
	color: #e1ecf3;
	transition: all 0.2s ease-in-out;
	display: inline;
	&:hover {
		color: #2c3e50;
	}
}

.helptip {
	.tooltip-inner {
		border-radius: 6px;
		background-color: #2c3e50;
		font-size: 14px;
	}

	span.arrow::before {
		border-top-color: #2c3e50 !important;
	}
}
