// import fonts

// import utilities
@import "utilities/index";

// import bootstrap
@import "vendors/bootstrap/bootstrap.scss";

// import base
@import "base/index";

// import layout
@import "layout/index";
// import icons
@import "icons/font-awesome/css/fontawesome-all.css";
@import "icons/simple-line-icons/css/simple-line-icons.css";

// import 'all/icons/weather-icons/css/weather-icons.min.css';
@import "icons/themify-icons/themify-icons.css";
@import "icons/material-design-iconic-font/css/materialdesignicons.min.css";

@import "fonts/index";
// import pages
@import "pages/index";

// import modals
@import "modals/index";

// import common styles
@import "common/index";

@import "ghost/index"; // select.form-control {
//     width: 40% !important;
//     text-transform: none;
// }
