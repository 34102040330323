@import "overview";

.dashboard-products {
    display: flex;
    align-items: center;

    button {
        opacity: 0.8;

        &.selected {
            opacity: 1;
        }
    }
}
.dashlist-tab {
    cursor: pointer;
}

.display-options {
    position:relative;
    float: right;
    margin-top: 5px;
    margin-right: 5px;
    z-index: 10;
    .display-icon {
        margin-left: 5px;
        color: #dddddd;
        cursor: pointer;
        &:hover {
            color: #17C9DC;
        }
        &.active {
            color: #17C9DC;
        }
    }
}
.dashboard-items-container {
    // box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.08);
    // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.185);
    border-radius: 10px;
    padding: 15px 0px 20px;
    .display-options {
        margin-right: -2px;
        margin-top: 15px;
    }
}

.dashboard-list-container {
    width: 100%;
    margin: 0 0;
    .dash-tab-navigation {
        display: inline-flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin-top: -15px;
        .nav {
    
            li {
                a {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    color: $battleship-grey;
                    font-weight: 400;
    
                    &.active {
                        font-weight: 600;
                        @extend .color-nile-blue;
                    }
                }
            }
        }
    }
    .dash-list-col {
        padding: 0;
    }
    .filter-buttons {
        margin-left: 20px;
    }
}

