.product-recommendations {
	.product-rec-header {
		@extend .custom-shadow;
		padding: 15px;
		border-radius: 8px;
	}

	table {
		@extend .clean-table;
		tr td,
		th {
			padding: 6px 0 !important;
			&:last-of-type {
				text-align: end;
				button {
					margin-left: auto;
					margin-right: 5px;
				}
			}
		}
	}
}
