.product-snapshot.modal-dialog {
    .product-img { 
        height: 125px;
        width: 125px;
        border-radius: 6px;
        border: 1px dashed #e1ecf3;
    }
    .obj-tag {
        padding: 6px;
        border: 1px solid #e1ecf3;
        border-radius: 6px;
        margin-right: 5px;
    }
    ul.snapshot-ingredients {
        list-style: none;
        padding-left: 0;
        li {
            margin: 10px 0;
            padding: 6px 12px;
            border: 1px solid #e1ecf3;
            border-radius: 6px;
            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
                border-radius: 6px;
            }
        }
    }
}