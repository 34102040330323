.starred-ingredients {
    max-width: 100%;
    width: 100%;
    .title {
        display: flex;
        h3.count {
            color: #6a8dec;
            margin-left: 5px;
        }
    }

    .starred-ingredients-list {
        display: flex;
        padding: 0 5px;

        .slide {
            width: 100%;
        }

        .ingredient-list-block {
            display: flex;
            justify-content: flex-start;
        }

        .slider-button-group {
            position: absolute;
            top:-34px;
            right: 0;

            button {
                margin: 5px;
                background: transparent;
                border: none;

                i {
                    font-size: 1.5em;
                    color: #586C7D;
                    opacity: 0.4;

                    &:hover{
                        opacity: 0.9;
                    }
                }

                &:focus {
                    border: none;
                    outline: none;
                }
            }
        }
    }
}
