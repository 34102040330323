.product-detail {
	.row {
		margin: 0;
	}
	display: flex;

	flex-flow: row wrap;
	justify-content: space-evenly;

	.product-type {
		display: inline-block;
		// border: 2px solid #d0dcea;
		padding: 0.375rem 0.75rem;
		font-size: 0.875rem;
		line-height: 1.5;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		font-weight: 600;
		border-radius: 6px;

		background-color: #3f65f1;
		border: 2px solid #3f65f1;

		span {
			// color: #697e99;
			color: #fff;
		}

		svg {
			// color: #486cf2;
			color: #fff;
		}
	}

	.product-ingredient-dietary {
		display: inline-block;
		border: 2px solid #d0dcea;
		padding: 0.375rem 0.75rem;
		font-size: 0.875rem;
		line-height: 1.5;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		font-weight: 600;
		border-radius: 6px;
		margin-right: 6px;
		margin-bottom: 6px;

		span {
			color: #697e99;
		}

		svg {
			// color: #f5b567;
			color: #697e99;
		}
	}

	.product-title {
		display: flex;

		h1 {
			font-size: 24px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: 1px;
			color: #273f52;
			flex: 1;
			.product-brand {
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.5px;
				span {
					margin-right: 20px;
				}
				@extend .color-battleship-grey;
				strong {
					font-weight: 900;
					font-size: 14px;
					font-style: normal;
					font-stretch: normal;
					letter-spacing: 0.5px;
					@extend .color-nile-blue;
				}
			}
		}

		span {
			font-size: 14px;
			color: #657280;
			margin-right: 16px;
		}
	}

	.detail {
		padding: 0px;
		.info {
			.image {
				display: flex;
				padding: 0px;
				justify-content: center;
				align-items: flex-start;
				img {
					border-radius: 8px;
					width: 200px;
					height: 200px;
				}
			}

			// description
			.description {
				.date {
					margin-top: 10px;
					padding: 12px 17px;
					background-color: #e1ecf3;
					border-radius: 5px;
					display: flex;
					flex-flow: row wrap;
					max-width: 100%;
					width: max-content;

					.created,
					.fulfilment,
					.product-code,
					.product-brand {
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: 0.5px;
						@extend .color-battleship-grey;
						strong {
							font-weight: 600;
							font-size: 14px;
							font-style: normal;
							font-stretch: normal;
							letter-spacing: 0.5px;
							@extend .color-nile-blue;
						}
					}
				}
			}
		}

		// stats section
		.persona-stats {
			margin: 30px 0;
			@extend .custom-shadow;
			display: flex;
			flex-flow: row wrap;
			border-radius: 10px;
			justify-content: space-between;

			div {
				display: inherit;
				flex-flow: column wrap;
			}
		}

		.product-personas {
			display: flex;
			flex-flow: row wrap;
			.persona {
				margin: {
					right: 20px;
					top: 20px;
				}
			}
		}
	}

	.chart {
		display: flex;
		flex-flow: column wrap;

		.chart-ingredients {
			padding: 10px;
			// height: 50%;
			// overflow: auto;
			margin-top: 30px;
			display: flex;
			flex-flow: column;
			align-items: center;

			.selected {
				transform: translateY(-5px);
				transition: ease transform 1s;
			}

			.view-all {
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.5px;
				color: #3266fa;
				cursor: pointer;
				padding: 10px 10px;
			}
		}
	}

	.product-objectives {
		width: 100%;
		border-bottom: 2px dashed #cedbe9;
		padding-bottom: 0.5rem;
	}
}

.product-personas-slider {
	width: 100%;
	align-self: flex-start;
	justify-self: flex-start;
	height: 200px;
}
