.bar-chart-card {
    height: 250px;
    .card-body {
        padding: 20px 0 0 0;  
        overflow-y:hidden;
        overflow-x:hidden;
        border-radius: 0px 0px 6px 6px;
    }
    .charts{
        display: flex;
        flex-direction: row;
        height: 100%;
        .divider {
            border-left: 2px solid #e1ecf39a;
            height: 150px;
            align-self: center;
            margin-top: -30px;
        }
        .chart-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            h4 {
                margin-bottom: 15px;
            }
            .btn-half {
                position: relative;
                color: #2c3e50;
                padding: 6px 12px;
                border-radius: 6px;
                background-color: #e1ecf3;
                border: 1px solid #e1ecf3;
                cursor: pointer;
                top: 0;
                transition: all 0.2s ease-in-out;
                width: 100%;
                &:hover {
                    top: 1px;
                    background-color: #e1ecf3;
                }
            }
            width: 50%;
            text-align: center;
            .bar-chart-wrapper {
                position: relative; //Required for responsive sizing of bar charts
            }
            .view {
                width: 100%;
                background-color: #e1ecf3;
                padding: 6px;
                margin-bottom: 0;
                margin-top: auto;
                align-self: flex-end;
                position: relative;     
                top: 0;
                transition: all 0.2s ease-in-out;
                color: #fff;
                cursor: pointer;
                &.products {
                    border-radius: 0 0 0 6px;
                    background-color: #3f65f1;
                    opacity: .75;
                }
                &.ingredients {
                    border-radius: 0 0 6px 0;
                    background-color: #f4a850;
                    opacity: .75;
                }
                &:hover {
                    top: 1px;
                    opacity: 100;
                    @extend .custom-shadow;
                }
            }
        }

    }

}