%center {
	text-align: center;
	vertical-align: middle;
}

%th-td {
	th {
		padding: 4px 7.5px;
	}
	td {
		padding: 12px 7.5px;
	}
}

.current-version {
	@extend %th-td;

	.header-indicator {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	th:first-child {
		width: 15%;
		font-weight: normal;
	}
	th {
		text-align: center;
	}
	td:first-child {
		width: 14%;
	}
	td:nth-child(2) {
		width: 15%;
		text-align: left;
	}

	th:nth-child(n + 2),
	td:nth-child(n + 2) {
		@extend %center;
	}

	td {
		vertical-align: middle;
		font-weight: normal;
		@extend .color-battleship-grey;
		button {
			font-weight: 600;
		}
		&.shopping-cart {
			position: relative;
			&::before {
				content: "\f218";
				font-family: "Font Awesome 5 Free";
				display: inline-block;
				font-weight: 600;
				position: absolute;
				left: 0;
				transform: translateX(-150%);
			}
		}
	}

	border-bottom: 2px dashed $powder-blue;
}

.version-list {
	@extend %th-td;

	th:first-child,
	td:first-child {
		width: 15%;
		font-weight: normal;
	}
	th:nth-child(2),
	td:nth-child(2) {
		width: 15%;
	}
	th {
		font-weight: normal;
	}
	td {
		vertical-align: middle;
		font-weight: normal;
		@extend .color-battleship-grey;

		&:nth-child(n + 3) {
			@extend %center;
		}

		button {
			font-weight: 600;
		}
	}
	tbody {
		tr:hover {
			background-color: $very-light-blue-50 !important;
			@include transition;
			// @extend .custom-shadow;
			cursor: pointer;
			td:first-of-type {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
			}
			td:last-of-type {
				border-bottom-right-radius: 6px;
				border-top-right-radius: 6px;
			}
		}
		.active {
			background-color: darken($very-light-blue-50, 5%);
			outline: 0 solid $bg-blue;
			transition: none;
		}
	}
}

.w-10 {
	width: 10%;
}

.w-20 {
	width: 20%;
}

.w-30 {
	width: 30%;
}

.w-40 {
	width: 40%;
}

.w-50 {
	width: 50%;
}

.w-60 {
	width: 60%;
}

.w-70 {
	width: 70%;
}

.w-80 {
	width: 80%;
}

.w-90 {
	width: 90%;
}

.w-100 {
	width: 100%;
}
