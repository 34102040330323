.recent-card {
	@media only screen and (min-width: 1224px) and (max-width: 1440px) {
		max-width: 625px;
	}
	max-width: 100%;
	.card-body {
		padding: 0;
		.recent-grid {
			display: flex;
			flex-direction: column;
			align-items: center;
			.row {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				justify-content: unset;
				width: 100%;
				.recent-card {
					@extend .recent-ghost;

					// Regular styles
					margin-right: 30px;
					margin-top: 0 !important;
					padding: 12px;
					border-radius: 6px;
					position: relative;
					.tag {
						position: absolute;
						z-index: 1;
						right: -2px;
						top: -2px;
						color: #fff;
						stroke: #fff;
						&.product {
							background-color: #3f65f1;
						}
						&.ingredient {
							background-color: #f4a850;
						}
						border-radius: 50%;
						height: 30px;
						width: 30px;
						padding: 6px;
						display: flex;
						align-items: center;
						justify-content: center;
						@extend .custom-shadow;
					}

					img {
						height: 100px;
						width: 100px;
						border-radius: 6px;
						position: relative;
						top: 0;
						transition: all 0.2s ease-in-out;
						cursor: pointer;
						&:hover {
							top: -2px;
							@extend .custom-shadow;
						}
					}
					h5 {
						padding: 10px 0;
						margin-bottom: 0;
					}
				}
			}
		}
	}
	height: 100%;
	box-shadow: none;
}
