
.slider-list {
    min-height: 180px;
    position: relative;
    max-width: 100%;

    .load-more-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #3f65f1;
        border: none;
        text-align: center;
        font-size: 1em;
        font-weight: lighter;
        margin: 5px;
        box-shadow: none;
        height: 40px;
        width: auto;
        background-color: #eaf1f6;
        &:hover {
            background-color: darken(#eaf1f6, 10%);
            color: #3f65f1;
        }
        &:not(:disabled):not(.disabled):active, &.disabled {
            background-color: darken(#eaf1f6, 20%);
            color: #3f65f1;
        }
    }

    .fetch-more-button {
        display: flex;
        flex-direction: column;
        transform: translateX(-50%);

        button {
            border: none;
            font-size: 1em;
            margin: 5px;
            height: 52px;
            width: 52px;
            border-radius: 50px;
            background-color: #e1ecf3;
            box-shadow: none;
        }
    }

    .slider__overlay {
        background: #f6f6f6;
        opacity: 0.5;
        width: 100%;
        height: 90%;
        position: absolute;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        left: -3%;
        top: 45px;
    }
    
    .slider-title {
        margin: 20px 0;
    }

    width: 100%;
    .BrainhubCarouselItem {
        margin-left: 12px;
    }
    .BrainhubCarousel,
    .BrainhubCarousel__container {
        overflow: visible;
    }

    .BrainhubCarousel__track {
        min-height: 150px;
    }

    .BrainhubCarousel__trackContainer {
        margin-left: -36px;
    }

    .BrainhubCarousel__customArrows {
        position: relative;
        z-index: 4;
        top: -120px;
        cursor: pointer;

        &.BrainhubCarousel__custom-arrowLeft {
            left: 93%;
        }

        &.BrainhubCarousel__custom-arrowRight {
            right: 0;
        }
    }
}
